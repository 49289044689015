import { useEffect, useRef } from "react";

function useDocumentTitle(title: string) {
  const prevTitleRef = useRef("Qbook");

  useEffect(() => {
    const prevTitle = prevTitleRef.current;
    if (title !== prevTitle) {
      document.title = title;
      prevTitleRef.current = title;
    }
    return () => {
      document.title = prevTitleRef.current;
    };
  }, [title]); // Only re-run when `title` changes
}

export default useDocumentTitle;
