import axiosClient, { DOMAIN_CONFIG_OBJ } from "../../utils/axiosInstance";

export const getBlogAsync = (blogId: any) => {
  const response = axiosClient().get("/public/get-blog-file/" + blogId);
  return response;
};

export const getMoreBlogsAsync = (pageNumber: Number) => {
  const response = axiosClient().get(
    `/public/get-blogs/${pageNumber || 0}?cardInfo.tags=${DOMAIN_CONFIG_OBJ.domainName.toLowerCase()}`,
  );
  return response;
};

export const upvoteBlogAsync = (id: any) => {
  const response = axiosClient().post("/upvote-blog/" + id);
  return response;
};

export const bookmarkBlogAsync = (id: string) => {
  const response = axiosClient().post("/bookmark-blog/" + id);
  return response;
};

export const copyBlogAsync = (filePath: any) => {
  const response = axiosClient().post("/blogs/clone", { filePath: filePath });
  return response;
};

export const getUserBlogBookmarksAsync = (pageNumber: Number) => {
  const response = axiosClient().get(`/get-bookmarked-blogs/${pageNumber}`);
  return response;
};
