/**
 * @fileoverviewThis file contains the UserProfile component, which allows users to edit their profile.
 * @module UserProfile
 * @category UserProfile
 * @copyright (C) 2024 qBraid Development Team
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Grid } from "@mui/material";
import ProfileDetails from "./components/profile-details";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/hooks";
import { ErrorTypes, InputTypes } from "./types";
import { handleSnackbarOpen } from "../../../reduxStore/features/snackbarSlice";
import { AxiosError, AxiosResponse } from "axios";
import { email, axiosClient } from "../../../utils/axiosInstance";
import { sendRGAEvent } from "../../../utils/rgva";

/**
 * EditProfile component.
 * @returns {JSX.Element} The UserProfile component.
 */

const EditProfile = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userData: userDetails } = useAppSelector((state) => state.auth);

    const [nameError, setNameError] = useState("");
    const [errors, setErrors] = useState<ErrorTypes>({
        githubUrl: "",
        googleScholar: "",
        linkedIn: "",
        twitterUrl: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [inputData, setInputData] = useState<InputTypes>({
        userName: "",
        firstName: "",
        lastName: "",
        profilePhoto: "",
        organization: "",
        role: "",
        interests: "",
        bio: "",
        linkedIn: "",
        githubUrl: "",
        googleScholar: "",
        personalWebsite: "",
        twitterUrl: "",
        levelOfEducation: "",
        isPrivate: false,
    });

    useEffect(() => {
        setInputData({
            userName: userDetails?.userName,
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            profilePhoto: userDetails?.personalInformation.profilePhoto,
            organization: "",
            role: "",
            interests:
                userDetails?.personalInformation?.interests.flat().toString() ??
                ["quantum computing"].flat().toString(),
            bio: userDetails?.personalInformation?.bio,
            linkedIn: userDetails?.personalInformation?.linkedIn,
            githubUrl: userDetails?.personalInformation?.githubUrl,
            googleScholar: userDetails?.personalInformation?.googleScholar,
            personalWebsite: userDetails?.personalInformation?.personalWebsite,
            twitterUrl: userDetails?.personalInformation?.twitterUrl,
            levelOfEducation:
                userDetails?.personalInformation?.levelOfEducation,
            isPrivate: userDetails?.personalInformation?.isPrivate,
        });
    }, [userDetails]);

    useEffect(() => {
        sendRGAEvent("User Profile", "Page View");
        if (!email) {
            Swal.fire({
                allowOutsideClick: false,
                confirmButtonColor: "var(--brand-color-1)",
                title: "You need to sign in to access this page.",
                icon: "info",
            }).then((result) => {
                if (result.value) {
                    navigate("/");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handles the change event for input fields.
     * @param {Event} e - The event object.
     */
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (e.target.name === "userName") {
            if (!e.target.value.match(/^[A-Za-z0-9_]*$/)) {
                setNameError("Special Characters and Spaces are Not Allowed");
            } else if (e.target.value === "") {
                setNameError("Username is required");
            } else {
                setNameError("");
            }
        }
        setInputData((ev) => ({
            ...ev,
            [e.target.name]: e.target.value,
        }));
    };

    /**
     * Handles the change event for the "isPrivate" checkbox.
     * @param {Event} e - The event object.
     */
    // const handleChangePrivate = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setInputData((ev) => ({
    //         ...ev,
    //         isPrivate: e.target.checked,
    //     }));
    // };

    /**
     * Updates the user profile.
     */
    const updateProfile = () => {
        if (inputData.userName === "") {
            setNameError("Username is required");
            dispatch(
                handleSnackbarOpen({
                    message: "Username is required",
                    severity: "info",
                })
            );
        } else if (inputData.firstName === "") {
            dispatch(
                handleSnackbarOpen({
                    message: "Please enter First name",
                    severity: "info",
                })
            );
        } else if (inputData.lastName === "") {
            dispatch(
                handleSnackbarOpen({
                    message: "Please enter Last name",
                    severity: "info",
                })
            );
        } else if (nameError) {
            dispatch(
                handleSnackbarOpen({ message: nameError, severity: "info" })
            );
        } else if (errors?.githubUrl && inputData?.githubUrl !== "") {
            dispatch(
                handleSnackbarOpen({
                    message: "Please check your gitHub URL",
                    severity: "info",
                })
            );
        } else if (errors?.twitterUrl && inputData?.twitterUrl !== "") {
            dispatch(
                handleSnackbarOpen({
                    message: "Please check your twitter URL",
                    severity: "info",
                })
            );
        } else if (errors?.googleScholar && inputData?.googleScholar !== "") {
            dispatch(
                handleSnackbarOpen({
                    message: "Please check your googleScholar URL",
                    severity: "info",
                })
            );
        } else if (errors?.linkedIn && inputData?.linkedIn !== "") {
            dispatch(
                handleSnackbarOpen({
                    message: "Please check your linkedIn URL",
                    severity: "info",
                })
            );
        } else {
            setIsSubmitting(true);
            const fieldsToAssign = {
                userName: inputData?.userName,
                firstName: inputData?.firstName,
                lastName: inputData?.lastName,
                personalInformation: {
                    profilePhoto: selectedImage,
                    bio: inputData?.bio,
                    linkedIn: inputData?.linkedIn,
                    githubUrl: inputData?.githubUrl,
                    googleScholar: inputData?.googleScholar,
                    organization: inputData?.organization,
                    interests: inputData?.interests
                        ?.split(/[.,!,?,;,...]/)
                        .toString(),
                    twitterUrl: inputData?.twitterUrl,
                    levelOfEducation: inputData?.levelOfEducation,
                    isPrivate: inputData?.isPrivate,
                },
            };
            axiosClient()
                .put("/edit-user-personal-info", { fieldsToAssign })
                .then((res: AxiosResponse) => {
                    if (res.data.success) {
                        dispatch(
                            handleSnackbarOpen({
                                message: res.data.message,
                                severity: "success",
                            })
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 20);
                    } else {
                        dispatch(
                            handleSnackbarOpen({
                                message: res.data.message,
                                severity: "error",
                            })
                        );
                    }
                    setIsSubmitting(false);
                })
                .catch((err: AxiosError | any) => {
                    dispatch(
                        handleSnackbarOpen({
                            message: err.response.data.message,
                            severity: "error",
                        })
                    );
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <Container maxWidth='xl' sx={{mt: 8}}>
            <Grid sx={{ width: "100%" }}>
                <ProfileDetails
                    handleChange={handleChange}
                    inputData={inputData}
                    updateProfile={updateProfile}
                    setSelectedImage={setSelectedImage}
                    nameError={nameError}
                    errors={errors}
                    setErrors={setErrors}
                    userDetails={userDetails}
                    isSubmitting={isSubmitting}
                />
            </Grid>
        </Container>
    );
};

export default EditProfile;
