import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { ACCOUNT_URL, DOMAIN_CONFIG_OBJ } from "../../../utils/axiosInstance";
import { useRouteError } from "react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QbookHeader from "../../components/header";
type Props = {};

const ErrorBoundaryPage = (props: Props) => {
  const error: any = useRouteError();
  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: "1em",
        position: "relative",
        background: "var(--bg-primary-clr1)",
      }}
    >
      <QbookHeader />
      <Grid
        item
        container
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: "1em",
          maxWidth: "500px !important",
        }}
      >
        <Grid item xs={12}>
          <img
            alt="qbook_logo"
            src={DOMAIN_CONFIG_OBJ.simpleLogo}
            width={100}
          />
        </Grid>
        <Typography variant="h6" color="indianred">
          {DOMAIN_CONFIG_OBJ.errorPage.title}
        </Typography>
        <Accordion sx={{ maxWidth: "700px", minWidth: "300px" }}>
          <AccordionSummary
            aria-controls="error-panel-content"
            id="error-panel-content"
            sx={{
              ":hover": {
                backgroundColor: "#8D36D126",
              },
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6" color="var(--text-primary-clr2)">
              {DOMAIN_CONFIG_OBJ.errorPage.accordionTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ overflow: "hidden" }}>
            {error.message || JSON.stringify(error) || "Not available"}
          </AccordionDetails>
        </Accordion>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Button
              fullWidth
              variant="qbookStyledBtn"
              sx={{ textTransform: "none" }}
              onClick={() => {
                window.location.href = "/blogs";
              }}
            >
              Blogs
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Button
              fullWidth
              variant="qbookStyledBtn"
              sx={{ textTransform: "none" }}
              onClick={() => {
                window.location.href = ACCOUNT_URL;
              }}
            >
              Account
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorBoundaryPage;
