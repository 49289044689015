import { createSlice } from "@reduxjs/toolkit";
export interface ThemeState {
  tabValue: string;
}
const initialState: ThemeState = {
  tabValue: "/admin",
};

const adminTabSlice = createSlice({
  name: "adminTab",
  initialState: initialState,
  reducers: {
    setAdminTabValue: (state, { payload }) => {
      state.tabValue = payload;
    },
  },
});

export const { setAdminTabValue } = adminTabSlice.actions;
export default adminTabSlice.reducer;
