import { Grid, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

export const WhyChooseData = {
  crs: <MenuBookIcon sx={{ width: "40%", height: "40%" }} />,
  std: <GroupsIcon sx={{ width: "40%", height: "40%" }} />,
  cls: <SchoolIcon sx={{ width: "40%", height: "40%" }} />,
  org: <WorkHistoryIcon sx={{ width: "40%", height: "40%" }} />
};

const WhyChooseCard = ({
  label,
  value,
  icon,
  bgColor,
  selectionHandler
}: {
  label: string;
  value: string;
  icon: any;
  bgColor: string;
  selectionHandler?: () => {};
}) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={6}
      md={6}
      lg={6}
      padding={1}
      minHeight={155}
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
          borderRadius: "15px",
          backgroundColor: "var(--landing-choose-card-bg)",
          overflow: "hidden"
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
          maxHeight={{ lg: "inherit", md: "100px", sm: "80px", xs: "50px" }}
          justifyContent={{
            lg: "center",
            md: "flex-start",
            sm: "flex-start",
            xs: "flex-start"
          }}
          sx={{
            background: bgColor,
            color: "var(--landing-choose-card-text1)",
            // borderRadius: "5px",
            display: "flex",

            alignItems: "center",
            textAlign: "left",
            height: "inherit"
          }}
        >
          {icon}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={10}
          padding={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <Typography
            variant="h6"
            color="var(--landing-choose-card-text2)"
            textAlign="left"
            fontWeight="700"
            fontSize="21px"
          >
            {label}
          </Typography>
          <Typography
            variant="body2"
            color="var(--landing-choose-card-text3)"
            sx={{
              textAlign: "left",
              fontSize: "17px",
              fontWeight: 600,
              maxHeight: "80px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "initial",
              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical"
            }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhyChooseCard;
