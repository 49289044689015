// these services are integrating here,
// bcs normal users not allowed to perform crud in blogs
// from qbook

import axiosClient from "../../utils/axiosInstance";

export const loadSubmittedBlogs = () => {
  const response = axiosClient().get("/get-submitted-blogs");
  return response;
};

export const deleteBlog = (blogId: string) => {
  const response = axiosClient().delete(`/delete-blog/${blogId}`);
  return response;
};
