import { AppBar, Box, Divider, Typography } from "@mui/material";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";
import { useAppSelector } from "../../../../../reduxStore/hooks";
import { QBOOK_DOMAIN_VALUES } from "../../../../../utils/constants/domainNameValues";
import NavigationBar from "../NavigationBar";
import AccountMenuDropdown from "../../../../components/header/components/accountMenuDropdown";

type Props = {};

const AdminHeader = (props: Props) => {
    const { isDark } = useAppSelector((state) => state.theme);

    return (
        <AppBar
            position="sticky"
            sx={{
                background: "var(--bg-primary-clr2)",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "none",
            }}
            cy-data="admin-header-container"
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", gap: "5px" }}>
                    <div
                        className={
                            DOMAIN_CONFIG_OBJ.domainName.includes(
                                QBOOK_DOMAIN_VALUES.quera
                            )
                                ? "admin-navigation-logo-container"
                                : "navigation-logo-container"
                        }
                    >
                        <img
                            src={
                                DOMAIN_CONFIG_OBJ.domainName ===
                                QBOOK_DOMAIN_VALUES.quera
                                    ? isDark
                                        ? DOMAIN_CONFIG_OBJ.darkThemeLogo
                                        : DOMAIN_CONFIG_OBJ.lightThemeLogo
                                    : DOMAIN_CONFIG_OBJ.simpleLogo
                            }
                            className={
                                DOMAIN_CONFIG_OBJ.domainName !==
                                QBOOK_DOMAIN_VALUES.quera
                                    ? "navigation-logo"
                                    : "navigation-logo-quera"
                            }
                            alt="navigation"
                            cy-data="admin-navigation-logo"
                        />
                        {DOMAIN_CONFIG_OBJ.domainName !==
                            QBOOK_DOMAIN_VALUES.quera && (
                            <span className="navigation-title">
                                {DOMAIN_CONFIG_OBJ.domainName}
                            </span>
                        )}
                    </div>
                </Box>
                <Box
                    sx={(theme) => ({
                        display: "flex",
                        borderRadius: "29px",
                        border: ".5px solid var(--accent-clr1)",
                        padding: "2px 35px",
                        marginRight: "35px",
                        color: "var(--text-primary-clr1)",
                        backgroundColor: "var(--admin-center-text-bg)",
                        [theme.breakpoints.down("md")]: {
                            display: "none",
                        },
                    })}
                >
                    <Divider />
                    <Typography
                        variant="h5"
                        sx={{ color: "var(--text-primary-clr1)" }}
                        cy-data="admin-navigation-title"
                    >
                        {DOMAIN_CONFIG_OBJ.domainName} Admin Page
                    </Typography>
                    <Divider />
                </Box>
                <AccountMenuDropdown />
            </Box>
            <NavigationBar />
        </AppBar>
    );
};

export default AdminHeader;
