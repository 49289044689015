/**
 * @fileOverview This file contains all three whitelisted domains, these are common in domainObject
 * and other locations in the qbook where ever its used, used for consistency for the domain name
 *
 */

export const QBOOK_DOMAIN_VALUES = {
  qbraid: "qBraid",
  qusteam: "QuSTEAM",
  quera: "QuEra",
};
