import React, { useState, useCallback } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import pdfmake from "pdfmake/build/pdfmake";
import {
  PageOrientation,
  StyleDictionary,
  TDocumentDefinitions
} from "pdfmake/interfaces";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { CertificateIcon } from "./CertificateIcon";
import { DOMAIN_CONFIG_OBJ } from "../../../../../../utils/axiosInstance";
import { getBase64ImageFromURL } from "../../../../../../reduxStore/services/adminCourseServices";
import {
  sendAttachedEmailAction,
  updateUserCertificateAction
} from "../../../../../../reduxStore/middleware/adminCourseMiddleware";
import { handleSnackbarOpen } from "../../../../../../reduxStore/features/snackbarSlice";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../../reduxStore/hooks";

// Move this outside of the component to avoid recreating on each render
pdfmake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
  }
};

interface Props {
  setCertificateLoading: (loading: boolean) => void;
  handleCourseRefresh: () => void;
  certificateLoading: boolean;
}

const CertificateGenerator: React.FC<Props> = ({
  setCertificateLoading,
  handleCourseRefresh,
  certificateLoading
}) => {
  const dispatch = useAppDispatch();
  const [isCertificateDisabled] = useState<boolean>(false);
  const { selectedCourse, selectedUser } = useAppSelector(
    (state) => state.adminCourse
  );

  const getDocumentDefinitions =
    useCallback(async (): Promise<TDocumentDefinitions> => {
      try {
        const certificate = await getBase64ImageFromURL(
          DOMAIN_CONFIG_OBJ.admin.certificate.certBgImageUrl
        );
        const date = new Date();
        const formattedDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

        return {
          background: (currentPage, pageSize) => ({
            image: (certificate as string) || "",
            width: pageSize.width,
            height: pageSize.height,
            absolutePosition: { x: 0, y: 0 }
          }),
          info: {
            title: "Certificate of completion",
            author: DOMAIN_CONFIG_OBJ.apiDomain,
            subject: "Certificate",
            keywords: "Certificate"
          },
          content: [
            {
              text: `${selectedUser.firstName} ${selectedUser.lastName}`,
              style: "name"
            },
            { text: selectedCourse.courseName, style: "courseTitle" },
            { text: formattedDate, style: "date" }
          ],
          styles: DOMAIN_CONFIG_OBJ.admin.certificate
            .styles as unknown as StyleDictionary,
          pageOrientation: DOMAIN_CONFIG_OBJ.admin.certificate
            .pageOrientation as PageOrientation
        };
      } catch (err) {
        console.error("Failed to create document definitions", err);
        dispatch(
          handleSnackbarOpen({
            severity: "error",
            message: `Failed to generate Certificate of completion!`
          })
        );
        // throw err;
        return { content: [] as any[] };
      }
    }, [selectedUser, selectedCourse, dispatch]);

  const sendCertificate = useCallback(async () => {
    setCertificateLoading(true);
    try {
      const docDef = await getDocumentDefinitions();
      const generatedPdf = pdfmake.createPdf(docDef);
      generatedPdf.getBase64(async (res) => {
        await dispatch(
          sendAttachedEmailAction({
            to: selectedUser.email,
            subject: `Certificate of Completion for ${selectedUser.firstName} ${selectedUser.lastName}`,
            message: "Congratulations on completing the course",
            base64Data: res
          })
        );
        dispatch(
          handleSnackbarOpen({
            severity: "success",
            message: `Successfully sent certificate to ${selectedUser.firstName} ${selectedUser.lastName} at ${selectedUser.email}`
          })
        );
        await dispatch(
          updateUserCertificateAction({
            userId: selectedUser.userId,
            courseId: selectedCourse._id,
            status: true
          })
        );
        await handleCourseRefresh();
      });
    } catch (error) {
      dispatch(
        handleSnackbarOpen({
          severity: "error",
          message: `Unable to send Certificate of completion!`
        })
      );
    } finally {
      setCertificateLoading(false);
    }
  }, [
    dispatch,
    getDocumentDefinitions,
    handleCourseRefresh,
    selectedCourse._id,
    selectedUser,
    setCertificateLoading
  ]);

  const viewCertificate = useCallback(async () => {
    setCertificateLoading(true);
    try {
      const docDef = await getDocumentDefinitions();
      const generatedPdf = pdfmake.createPdf(docDef);
      generatedPdf.open();
    } catch (error) {
      console.error("Failed to view certificate", error);
    } finally {
      setCertificateLoading(false);
    }
  }, [getDocumentDefinitions, setCertificateLoading]);

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="flex-end"
      alignItems="center"
      position="sticky"
      bottom={0}
      right={0}
      gap={1}
      padding="4px"
      sx={{ backgroundColor: "var(--bg-primary-clr1)" }}
    >
      <Grid item sx={{ color: "var(--accent-clr1)" }}>
        {certificateLoading && (
          <CircularProgress
            color="inherit"
            size="24px"
            sx={{ width: "16px", height: "16px" }}
            cy-data="admin-course-certificate-generator-loader"
          />
        )}
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          startIcon={<CertificateIcon />}
          sx={{ textTransform: "none" }}
          onClick={viewCertificate}
          disabled={isCertificateDisabled || certificateLoading}
          cy-data="admin-course-grader-view-certificate-btn"
        >
          View Certificate
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          startIcon={<CertificateIcon />}
          sx={{ textTransform: "none" }}
          onClick={sendCertificate}
          disabled={isCertificateDisabled || certificateLoading}
          cy-data="admin-course-grader-send-certificate-btn"
        >
          Send Certificate
        </Button>
      </Grid>
      {selectedUser?.isCertificateSent && (
        <Grid item>
          <Button
            startIcon={<VerifiedUserIcon />}
            sx={{
              textTransform: "none",
              color: "green"
            }}
          >
            Already Sent
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CertificateGenerator);
