import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loadSingleCourseData,
  retrieveAdminCourses,
  retrieveStudentsSolutionInCourseForFreeResponse,
  retrieveStudentsSolutionInCourseForMcq,
  sendAttachedEmail,
  updateAdminReviewForUsersFrqSolution,
  updateUserCertificateStatus,
} from "../services/adminCourseServices";
import { removeCourseIdFromRefreshingCourses } from "../features/adminCourseSlice";

export const retrieveAdminCoursesAction = createAsyncThunk(
  "adminCourse/getCourses",
  async (_, thunkAPI) => {
    try {
      const response = await retrieveAdminCourses();
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data.courses);
      }
      return thunkAPI.rejectWithValue({
        message: "failed to get admin courses",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to get admin courses",
      });
    }
  }
);

export const retrieveStudentsSolutionInCourseForMcqAction = createAsyncThunk(
  "adminCourse/getUserMcqSolutionsForCourse",
  async (
    { userId, courseId,pageNumber }: { userId: string; courseId: string,pageNumber: number },
    thunkAPI
  ) => {
    try {
      const response = await retrieveStudentsSolutionInCourseForMcq(
        userId,
        courseId,
        pageNumber
      );
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "Failed to get users response for mcq in course",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "failed to get users response for mcq in course",
      });
    }
  }
);

export const retrieveStudentsSolutionInCourseForFreeResponseAction =
  createAsyncThunk(
    "adminCourse/getUserFrqSolutionsForCourse",
    async (
      { userId, courseId,pageNumber }: { userId: string; courseId: string,pageNumber: number },
      thunkAPI
    ) => {
      try {
        const response = await retrieveStudentsSolutionInCourseForFreeResponse(
          userId,
          courseId,
          pageNumber
        );
        if (response.data && response.status === 200) {
          return thunkAPI.fulfillWithValue(response.data);
        }
        return thunkAPI.rejectWithValue({
          message: "Failed to get users response for frq in course",
        });
      } catch (error: any) {
        return thunkAPI.rejectWithValue({
          message: "failed to get users response for frq in course",
        });
      }
    }
  );

export const updateAdminReviewForUsersFrqSolutionAction = createAsyncThunk(
  "adminCourse/updateAdminReviewForUsersFrqSolution",
  async (
    {
      pointAwarded,
      reviewRemarks,
      frqSolutionId,
      answerAccepted,
    }: {
      pointAwarded: number;
      reviewRemarks?: string;
      frqSolutionId: string;
      answerAccepted: boolean;
    },
    thunkAPI
  ) => {
    try {
      const response = await updateAdminReviewForUsersFrqSolution({
        pointAwarded,
        reviewRemarks,
        frqSolutionId,
        answerAccepted,
      });
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "Failed to submit frq response review",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to submit frq response review",
      });
    }
  }
);

export const loadSingleCourseDataAction = createAsyncThunk(
  "adminCourse/loadSingleCourseData",
  async (
    {
      courseId,
    }: {
      courseId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await loadSingleCourseData(courseId);
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "Failed to load course",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to load course",
      });
    } finally {
      thunkAPI.dispatch(removeCourseIdFromRefreshingCourses({ courseId }));
    }
  }
);

export const sendAttachedEmailAction = createAsyncThunk(
  "adminCourse/sendAttachedEmail",
  async (
    {
      to,
      message,
      subject,
      base64Data,
    }: { to: string; message: string; subject: string; base64Data: string },
    thunkAPI
  ) => {
    try {
      const response = await sendAttachedEmail(
        to,
        message,
        subject,
        base64Data
      );
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return err;
    }
  }
);

export const updateUserCertificateAction = createAsyncThunk(
  "adminCourse/updateUserCertificateStatus",
  async (
    {
      userId,
      courseId,
      status,
    }: { userId: string; courseId: string; status: boolean },
    thunkAPI
  ) => {
    try {
      const response = await updateUserCertificateStatus(
        userId,
        courseId,
        status
      );
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return err;
    }
  }
);
