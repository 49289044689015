import { Components } from "@mui/material";

declare module "@mui/material/Button" {
  export interface ButtonPropsVariantOverrides {
    qbookAuth: true;
    qbookStyledBtn: true;
    userRole: true;
    glow: true;
  }
  interface ButtonPropsColorOverrides {
    authSubmit: true;
    authCancel: true;
    purchase: true;
    mcqSubmit: true;
    instructor: true;
    student: true;
    teachingAssistant: true;
    manager: true;
    owner: true;
  }
}

export const MUIComponent: Components = {
  MuiTextField: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          color: "#ffffff",
          backgroundColor: "var(--auth-tex-bg)",
          border: "1px solid transparent",
          borderRadius: "10px",
          fontFamily: "var(--global-font-family-primary), sans - serif",
          "&>.Mui-focused": {
            "&>.MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--accent-clr1) !important",
              outline: "none",
            },
          },
          "&>.MuiInputBase-root": {
            color: "white",
          },
        },
      },
    ],
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "qbookAuth" },
        style: {
          fontFamily: "var(--global-font-family-primary), sans - serif",
          backgroundColor: "white",
          textTransform: "none",
          borderRadius: "10px",
          color: "black",
          ":hover": {
            backgroundColor: "white",
            color: "black",
            boxShadow: "0 2px 14px rgba(240, 255, 72, .6)",
          },
        },
      },
      {
        props: { variant: "qbookAuth", size: "large" },
        style: { height: "2.8rem", fontSize: "1rem" },
      },
      {
        props: { variant: "qbookAuth", color: "authSubmit" },
        style: {
          backgroundColor: "var(--auth-btn-bg1)",
          color: "black",
          ":hover": {
            color: "black",
            backgroundColor: "var(--auth-btn-hov1)",
            boxShadow: "var(--auth-btn-shadow1)",
          },
        },
      },
      {
        props: { variant: "qbookAuth", color: "authCancel" },
        style: {
          backgroundColor: "var(--auth-btn-bg2)",
          color: "white",
          ":hover": {
            backgroundColor: "var(--auth-btn-hov2)",
            color: "white",
            boxShadow: "var(--auth-btn-shadow2)",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn" },
        style: {
          textTransform: "capitalize",
          border: "none",
          color: "white",
          backgroundImage:
            "linear-gradient(110deg,var(--accent-clr1) 50%,var(--accent-clr2) 50%)",
          backgroundSize: "222%",
          backgroundPosition: "right",
          transition:
            "background-position 200ms linear, letter-spacing 200ms linear",
          ":hover": {
            backgroundPosition: "left",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "success" },
        style: {
          backgroundImage:
            "linear-gradient(110deg,#8AB73A 50%,var(--accent-clr2) 50%)",
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "error" },
        style: {
          border: "1px solid var(--text-error2)",
          color: "var(--text-error2)",
          backgroundImage:
            "linear-gradient(110deg, var(--text-error2) 50%,transparent 50%)",
          ":hover": {
            color: "white",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "purchase" },
        style: {
          border: "1px solid #673ab7",
          color: "#673ab7",
          backgroundImage:
            "linear-gradient(110deg,  #673ab7 50%,transparent 50%)",
          ":hover": {
            color: "white",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "mcqSubmit" },
        style: {
          height: 26,
          fontSize: 14,
          fontWeight: 400,
          border: "none",
          color: "white",
          backgroundImage:
            "linear-gradient(110deg,  var(--accent-clr2) 50%,var(--accent-clr3) 50%)",
          ":hover": {
            color: "white",
          },
        },
      },
      {
        props: { variant: "userRole" },
        style: {
          fontFamily: "var(--global-font-family-primary), sans - serif",
          maxHeight: "30px",
          textTransform: "none",
          borderRadius: "5px",
          padding: "0",
          color: "var(--text-primary-clr1)",
          minWidth: "150px",
        },
      },
      {
        props: { variant: "userRole", color: "instructor" },
        style: {
          backgroundColor: "var(--admin-table-button-instructor)",
          ":hover": {
            border: "1px solid var(--admin-table-button-instructor)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "student" },
        style: {
          backgroundColor: "var(--admin-table-button-student)",
          color: "var(--text-gray1)",
          ":hover": {
            border: "1px solid var(--accent-clr3)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "teachingAssistant" },
        style: {
          backgroundColor: "var(--admin-table-button-ta)",
          ":hover": {
            border: "1px solid var(--admin-table-button-ta)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "manager" },
        style: {
          backgroundColor: "var(--admin-table-button-manager)",
          ":hover": {
            border: "1px solid var(--admin-table-button-manager)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "owner" },
        style: {
          backgroundColor: "var(--accent-clr2)",
          ":hover": {
            border: "1px solid var(--accent-clr2)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "var(--text-gray2)",
          },
        },
      },
      {
        props: { variant: "glow" },
        style: {
          textTransform: "capitalize",
          border: "none",
          color: "white",
          borderRadius: "5px",
          backgroundColor: "var(--accent-clr1)",
          ":hover": {
            boxShadow: "0px 0px 9px 2px var(--accent-clr2)",
            backgroundColor: "var(--accent-clr2)",
            color: "black",
          },
        },
      },
    ],
  },
};

export const MUIQueraComponent: Components = {
  MuiTextField: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          color: "#ffffff !important",
          backgroundColor: "var(--auth-tex-bg)",
          border: "1px solid transparent",
          borderRadius: "10px",
          fontFamily: "var(--global-font-family-primary), sans - serif",
          "&>.Mui-focused": {
            "&>.MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--accent-clr1) !important",
              outline: "none",
            },
          },
          "&>.MuiInputBase-root": {
            color: "white",
          },
        },
      },
    ],
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "qbookAuth" },
        style: {
          fontFamily: "var(--global-font-family-primary), sans - serif",
          backgroundColor: "white",
          textTransform: "none",
          borderRadius: "10px",
          color: "black",
          ":hover": {
            backgroundColor: "white",
            color: "black",
            boxShadow: "0 2px 14px rgba(240, 255, 72, .6)",
          },
        },
      },
      {
        props: { variant: "qbookAuth", size: "large" },
        style: { height: "2.8rem", fontSize: "1rem" },
      },
      {
        props: { variant: "qbookAuth", color: "authSubmit" },
        style: {
          backgroundColor: "var(--auth-btn-bg1)",
          color: "black",
          ":hover": {
            color: "black",
            backgroundColor: "var(--auth-btn-hov1)",
            boxShadow: "var(--auth-btn-shadow1)",
          },
        },
      },
      {
        props: { variant: "qbookAuth", color: "authCancel" },
        style: {
          backgroundColor: "var(--auth-btn-bg2)",
          color: "white",
          ":hover": {
            backgroundColor: "var(--auth-btn-hov2)",
            color: "white",
            boxShadow: "var(--auth-btn-shadow2)",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn" },
        style: {
          backgroundColor: "var(--auth-btn-bg2)",
          color: "white",
          ":hover": {
            backgroundColor: "var(--auth-btn-hov2)",
            color: "white",
            boxShadow: "var(--auth-btn-shadow2)",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "success" },

        style: {
          backgroundColor: "#759d30",
          ":hover": {
            backgroundColor: "#8AB73A",
            color: "white",
            boxShadow: "var(--auth-btn-shadow-success)",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "error" },
        style: {
          backgroundColor: "var(--text-error2)",
          ":hover": {
            backgroundColor: "var(--text-error2)",
            color: "white",
            boxShadow: "var(--auth-btn-shadow-error)",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "purchase" },
        style: {
          backgroundColor: "var(--auth-btn-bg1)",
          color: "black",
          ":hover": {
            backgroundColor: "var(--auth-btn-bg1)",
            boxShadow: "var(--auth-btn-shadow1)",
            color: "black",
          },
        },
      },
      {
        props: { variant: "qbookStyledBtn", color: "mcqSubmit" },
        style: {
          height: 26,
          fontSize: 14,
          fontWeight: 400,
          border: "none",
          color: "white",
        },
      },
      {
        props: { variant: "userRole" },
        style: {
          fontFamily: "var(--global-font-family-primary), sans - serif",
          maxHeight: "30px",
          textTransform: "none",
          borderRadius: "5px",
          padding: "0",
          color: "var(--text-primary-clr1)",
          minWidth: "150px",
        },
      },
      {
        props: { variant: "userRole", color: "instructor" },
        style: {
          backgroundColor: "var(--admin-table-button-instructor)",
          ":hover": {
            border: "1px solid var(--admin-table-button-instructor)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "student" },
        style: {
          backgroundColor: "var(--admin-table-button-student)",
          color: "var(--text-gray1)",
          ":hover": {
            border: "1px solid var(--accent-clr3)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "teachingAssistant" },
        style: {
          backgroundColor: "var(--admin-table-button-ta)",
          ":hover": {
            border: "1px solid var(--admin-table-button-ta)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "manager" },
        style: {
          backgroundColor: "var(--admin-table-button-manager)",
          ":hover": {
            border: "1px solid var(--admin-table-button-manager)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "grey",
          },
        },
      },
      {
        props: { variant: "userRole", color: "owner" },
        style: {
          backgroundColor: "var(--accent-clr2)",
          ":hover": {
            border: "1px solid var(--accent-clr2)",
            backgroundColor: "var(--admin-table-button-hover)",
            color: "var(--text-gray2)",
          },
        },
      },
      {
        props: { variant: "glow" },
        style: {
          textTransform: "capitalize",
          border: "none",
          color: "white",
          borderRadius: "5px",
          backgroundColor: "var(--accent-clr1)",
          ":hover": {
            boxShadow: "0px 0px 9px 2px var(--accent-clr2)",
            backgroundColor: "var(--accent-clr2)",
            color: "black",
          },
        },
      },
    ],
  },
};
