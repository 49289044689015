import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  loadResourceAction,
  submitQuestionResponseAction,
} from "../middleware/quizMiddleware";

export interface QuizStoreState {}
const initialState: QuizStoreState = {};
const quizSlice = createSlice({
  name: "quiz",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    // load resources
    builder.addCase(loadResourceAction.pending, (state) => {});
    builder.addCase(
      loadResourceAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(loadResourceAction.rejected, (state, action) => {});
    // submit question response
    builder.addCase(submitQuestionResponseAction.pending, (state) => {});
    builder.addCase(
      submitQuestionResponseAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(
      submitQuestionResponseAction.rejected,
      (state, action) => {},
    );
  },
});

export default quizSlice.reducer;
