import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BASE_URL,
  WEBSOCKET_URL,
  createWebsocketAsync,
  getKernelAsync,
  interruptKernelAsync,
  listKernelAsync,
  restartKernelAsync,
  shutdownKernelAsync,
  startKernelAsync,
} from "../services/kernelService";
import { RootState } from "../store";
import {
  handleAddKernel,
  handleClearQueue,
  handleDeleteKernel,
  handleStoreCurrentKernel,
  handleUpdateKernelState,
} from "../features/kernalSlice";
import { DEFAULT_KERNEL_NAME } from "../../utils/axiosInstance";

// the number of retries to get the correct EG
const NUMBER_RETRIES = 5;
const KERNEL_NAME = DEFAULT_KERNEL_NAME;
const WebSocketClient = require("websocket").w3cwebsocket;

// for redux web sockets are not stored in redux state
// live sessions are kept in middlewares
// TODO : define types for socketHolder
let socketHolder: any;

export const startKernelAction = createAsyncThunk(
  "kernel/startKernal",
  async ({ kernel_type }: { kernel_type: string }, thunkAPI) => {
    try {
      const response = await startKernelAsync(kernel_type);
      thunkAPI.fulfillWithValue(response.data.body);
    } catch (error) {
      thunkAPI.rejectWithValue({ message: "Failed to start kernel" });
    }
  },
);

export const getKernelAction = createAsyncThunk(
  "kernel/getKernal",
  async (
    { kernel_id, attemptNumber }: { kernel_id: any; attemptNumber?: number },
    thunkAPI,
  ) => {
    if (!attemptNumber) {
      console.log(`Getting kernel from ${BASE_URL}/api/kernels/${kernel_id}`);
      attemptNumber = 0;
    }
    try {
      const response = await getKernelAsync(kernel_id);
      // console.log("response from get kernel action ", response);
      thunkAPI.dispatch(handleAddKernel({ kernel: response.data }));
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      // console.log("error at get kernel action ", err);
      if (attemptNumber < NUMBER_RETRIES) {
        const incrementedAttempt = attemptNumber + 1;
        thunkAPI.dispatch(
          getKernelAction({
            kernel_id: kernel_id,
            attemptNumber: incrementedAttempt,
          }),
        );
      } else {
        localStorage.removeItem("kernelId"); // Remove the kernel ID from localStorage
        localStorage.removeItem("kernelName"); // Remove the kernel name from localStorage
        thunkAPI.dispatch(handleDeleteKernel({ kernel_id: kernel_id }));
        thunkAPI.rejectWithValue({ message: "Get kernel failed" });
      }
    }
  },
);

export const shutdownKernelAction = createAsyncThunk(
  "kernel/shutdownKernel",
  async ({ kernel_id }: { kernel_id: any }, thunkAPI) => {
    try {
      const response = await shutdownKernelAsync(kernel_id);
      return thunkAPI.fulfillWithValue(response.data.body);
    } catch (error) {
      thunkAPI.rejectWithValue({ message: "Shutdown kernel failed" });
    }
  },
);

export const restartKernelAction = createAsyncThunk(
  "kernel/restartKernel",
  async ({ kernel_id }: { kernel_id: any }, thunkAPI) => {
    try {
      thunkAPI.dispatch(handleUpdateKernelState("Starting..."));
      const restartKernelResponse = await restartKernelAsync(kernel_id);
      if (restartKernelResponse.status === 200) {
        if (socketHolder) {
          socketHolder.close();
        }

        const client = new WebSocketClient(
          `${WEBSOCKET_URL}/api/kernels/${kernel_id}/channels`,
        );
        client.onopen = () => {
          thunkAPI.dispatch(handleUpdateKernelState("Ready"));
          console.log(`Websocket re-connected to kernel with id ${kernel_id}`);
        };
        client.onclose = () => {
          thunkAPI.dispatch(handleUpdateKernelState("Failed"));
          console.log(`Websocket disconnected to kernel with id ${kernel_id}`);
        };
        socketHolder = client;
        return thunkAPI.fulfillWithValue(client);
      }
      thunkAPI.dispatch(handleUpdateKernelState("Failed"));
      socketHolder.close();
      return thunkAPI.rejectWithValue({ message: "Error at kernel restart" });
    } catch (error) {
      console.log("error at kernel restart ", error);
      thunkAPI.dispatch(handleUpdateKernelState("Failed"));
      socketHolder.close();
      return thunkAPI.rejectWithValue({ message: "Restart kernel failed" });
    }
  },
);

export const interruptKernelAction = createAsyncThunk(
  "kernel/interruptKernel",
  async ({ kernel_id }: { kernel_id: any }, thunkAPI) => {
    try {
      const response = await interruptKernelAsync(kernel_id);
      return thunkAPI.fulfillWithValue(response.data.body);
    } catch (error) {
      thunkAPI.rejectWithValue({ message: "Interrupt kernel failed" });
    }
  },
);

export const shutdownAllKernalAction = createAsyncThunk(
  "kernel/shutdownAll",
  async (_, thunkAPI) => {
    try {
      const response = await listKernelAsync();
      let kernels = JSON.parse(response.data.body).map(
        (kernel: any) => kernel.id,
      );
      let shutdownKernels = await Promise.all(
        kernels.map(async (kernel_id: any) => {
          await shutdownKernelAsync(kernel_id);
          return kernel_id;
        }),
      );
      for (let kernel of shutdownKernels) {
        thunkAPI.dispatch(handleDeleteKernel(kernel.id));
      }
      return thunkAPI.fulfillWithValue({
        message: "all kernels has been terminated",
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: "Shutting down all kernels failed",
      });
    }
  },
);
export const createWebsocket = createAsyncThunk(
  "kernel/createWebSocket",
  async (
    { kernelId, iterationNumber }: { kernelId: any; iterationNumber: any },
    thunkAPI,
  ) => {
    try {
      const client = await createWebsocketAsync(kernelId);
      if (client) {
        // store the web socket in middleware,
        socketHolder = client;
        socketHolder.onclose = () => {
          thunkAPI.dispatch(handleUpdateKernelState("Failed"));
          console.log(`Websocket disconnected to kernel with id ${kernelId}`);
        };
        thunkAPI.dispatch(handleUpdateKernelState("'Ready'"));
        thunkAPI.fulfillWithValue({ message: "Connection created" });
      } else {
        if (iterationNumber < NUMBER_RETRIES) {
          await new Promise((resolve) => setTimeout(resolve, 50));
          const iterationCount: any = iterationNumber + 1;
          thunkAPI.dispatch(
            createWebsocket({
              kernelId: kernelId,
              iterationNumber: iterationCount,
            }),
          );
        } else {
          thunkAPI.dispatch(handleUpdateKernelState("Failed"));
          thunkAPI.rejectWithValue({ message: "error at web socker creation" });
        }
      }
    } catch (err) {
      if (iterationNumber < NUMBER_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, 50));
        const iterationCount: any = iterationNumber + 1;
        thunkAPI.dispatch(
          createWebsocket({
            kernelId: kernelId,
            iterationNumber: iterationCount,
          }),
        );
      } else {
        thunkAPI.dispatch(handleUpdateKernelState("Failed"));
        thunkAPI.rejectWithValue({ message: "error at web socket creation" });
      }
    }
  },
);
export const killWebsocket = createAsyncThunk(
  "kernel/killWebSocket",
  async (_, thunkAPI) => {
    try {
      const response = await new Promise((resolve, reject) => {
        try {
          if (socketHolder) {
            socketHolder.close();
            socketHolder = null;
          }
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
      thunkAPI.fulfillWithValue(response);
    } catch (error) {
      thunkAPI.rejectWithValue({ message: "Error from kill socket" });
    }
  },
);

export const runStartupSequenceAction = createAsyncThunk(
  "kernel/startupSequence",
  async ({ kernel_type }: { kernel_type: any }, thunkAPI) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const { user } = appState.auth;
      let kernelId = localStorage.getItem("kernelId");  // Retrieve the kernel ID from localStorage
      let kernelName = localStorage.getItem("kernelName");  // Retrieve the kernel name from localStorage
      if (!user) {
        thunkAPI.dispatch(handleUpdateKernelState("Failed"));
        return thunkAPI.rejectWithValue({ success: false });
      }
      
      if (socketHolder) {
        thunkAPI.dispatch(handleUpdateKernelState("Ready"));
        return thunkAPI.fulfillWithValue({ success: true });
      }

      let filteredKernels = [];
      let startingFilteredKernels = [];

      if (!kernelId || (kernel_type && kernelName !== kernel_type)) {  // Only proceed if no kernel ID is stored or the kernel name has changed
        try {
          const kernalresponse = await listKernelAsync();
          filteredKernels = kernalresponse?.data?.filter((kernel: any) => {
            return (
              kernel.name === kernel_type &&
              kernel.execution_state === "idle" &&
              kernel.connections === 0 &&
              Date.now() - Date.parse(kernel.last_activity) > 180000
            );
          });
          if (filteredKernels.length === 0) {
            startingFilteredKernels = kernalresponse?.data?.filter(
              (kernel: any) => {
                return (
                  kernel.name === kernel_type &&
                  kernel.execution_state === "starting" &&
                  kernel.connections === 0 &&
                  Date.now() - Date.parse(kernel.last_activity) > 360000
                );
              }
            );
            if (startingFilteredKernels.length === 0) {
              const startKernel = (TARGET_KERNEL_NAME: any, iteration: any) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res = await startKernelAsync(TARGET_KERNEL_NAME);
                    kernelId = res?.data?.id;
                    localStorage.setItem("kernelId", kernelId as string); // Store the kernel ID in localStorage
                    localStorage.setItem("kernelName", TARGET_KERNEL_NAME); // Store the kernel name in localStorage
                    return resolve(kernelId);
                  } catch (err) {
                    if (iteration !== 0) {
                      thunkAPI.dispatch(handleUpdateKernelState("Failed"));
                      return reject(err);
                    }
                    try {
                      await startKernel(TARGET_KERNEL_NAME, iteration + 1);
                    } catch (errr) {
                      reject(errr);
                    }
                  }
                });
              };
              try {
                const kernel = kernel_type || DEFAULT_KERNEL_NAME; 
                await startKernel(kernel, 0);
              } catch (err) {
                thunkAPI.dispatch(handleUpdateKernelState("Unavailable"));
                return thunkAPI.rejectWithValue({ success: false });
              }
            } else {
              kernelId = startingFilteredKernels[Math.floor(Math.random() * startingFilteredKernels.length)]?.id;
              localStorage.setItem("kernelId", kernelId as string); // Store the kernel ID in localStorage
              localStorage.setItem("kernelName", kernel_type); // Store the kernel name in localStorage
            }
          } else {
            kernelId = filteredKernels[0]?.id;
            localStorage.setItem("kernelId", kernelId as string); // Store the kernel ID in localStorage
            localStorage.setItem("kernelName", kernel_type); // Store the kernel name in localStorage
          }
        } catch (error) {
          thunkAPI.dispatch(handleUpdateKernelState("Unavailable"));
          return thunkAPI.rejectWithValue({ success: false });
        }
      }

      try {
        const data = await thunkAPI.dispatch(getKernelAction({ kernel_id: kernelId })).unwrap();
        if (data?.connections !== 0) {
          thunkAPI.dispatch(runStartupSequenceAction({ kernel_type: kernel_type }));
        } else {
          thunkAPI.dispatch(handleStoreCurrentKernel({ kernel: data }));
          try {
            thunkAPI.dispatch(createWebsocket({ kernelId: kernelId, iterationNumber: 0 }));
          } catch (error) {
            thunkAPI.dispatch(handleUpdateKernelState("Failed"));
            return thunkAPI.rejectWithValue({ success: false });
          }
        }
      } catch (error) {
        thunkAPI.dispatch(handleUpdateKernelState("Failed"));
        return thunkAPI.rejectWithValue({ success: false });
      }
    } catch (err) {
      thunkAPI.dispatch(handleUpdateKernelState("Failed"));
      return thunkAPI.rejectWithValue({ success: false });
    }
  }
);

export const prepareRequest = createAsyncThunk(
  "kernel/prepareRequest",
  async (
    {
      code,
      failureCallback,
      successCallback,
      completeCallback,
    }: {
      code: any;
      failureCallback: any;
      successCallback: any;
      completeCallback: any;
    },
    thunkAPI,
  ) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const { user } = appState.auth;
      const { kernelState } = appState.kernel;
      if (kernelState !== "Ready") {
        failureCallback({
          content: "Kernel not available, please try again",
          type: "text",
        });
        completeCallback();
        return thunkAPI.rejectWithValue({ message: "Kernel not free" });
      }
      if (!user) {
        failureCallback({
          content: "No user. You must be logged in to execute code",
          type: "text",
        });
        completeCallback();
        return thunkAPI.rejectWithValue({ message: "User not found" });
      }
      let messageId = [...Array(32)]
        .map(() => Math.floor(Math.random() * 10).toString())
        .join("");
      let message = {
        header: {
          username: user?.attributes?.email,
          version: "5.0",
          session: "",
          msg_id: messageId,
          msg_type: "execute_request",
        },
        parent_header: {},
        channel: "shell",
        content: {
          code,
          silent: false,
          store_history: false,
          user_expressions: {},
          allow_stdin: false,
        },
        metadata: {},
        buffers: {},
      };

      const response = await thunkAPI.dispatch(
        executeCode({
          message,
          userReferece: user,
          successCallback,
          failureCallback: (err: any) => {
            failureCallback(err);
            thunkAPI.dispatch(handleClearQueue({}));
          },
          completeCallback,
        }),
      );
      return thunkAPI.fulfillWithValue(response);
    } catch (err: any) {
      console.log("error at code prepare code ", err);
      failureCallback({
        content: err.message || "Code preparation errored",
        type: "text",
      });
      if (socketHolder) {
        thunkAPI.dispatch(handleUpdateKernelState("Ready"));
      } else {
        thunkAPI.dispatch(handleUpdateKernelState("Unavailable"));
      }
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const executeCode = createAsyncThunk(
  "kernel/executeCode",
  async (
    {
      message,
      userReferece,
      successCallback,
      failureCallback,
      completeCallback,
    }: {
      message: any;
      userReferece: any;
      successCallback: any;
      failureCallback: any;
      completeCallback: any;
    },
    thunkAPI,
  ) => {
    const apState = thunkAPI.getState() as RootState;
    const { user } = apState.auth;
    if (!socketHolder) {
      failureCallback({
        content: "Websocket terminated, please restart kernel",
        type: "text",
      });
      thunkAPI.dispatch(handleUpdateKernelState("Failed"));
      return thunkAPI.rejectWithValue({
        message: "Websocket not connected",
      });
    }
    if (!user) {
      failureCallback({
        content: "You probably are not authenticated",
        type: "text",
      });
      return thunkAPI.rejectWithValue({
        message: "You probably are not authenticated",
      });
    }
    try {
      const response = await new Promise(async (resolve, reject) => {
        console.log(
          `Executing message with ID ${message.header.msg_id} on websocket`,
        );
        thunkAPI.dispatch(handleUpdateKernelState("Busy"));
        socketHolder.send(JSON.stringify(message));

        socketHolder.onerror = (evt: any) => {
          if (socketHolder) {
            thunkAPI.dispatch(handleUpdateKernelState("Ready"));
          } else {
            thunkAPI.dispatch(handleUpdateKernelState("Unavailable"));
          }
          failureCallback({
            content: "Error on socket connection",
            type: "text",
          });
          return reject([null, evt]);
        };
        socketHolder.onmessage = (evt: any) => {
          let obj = JSON.parse(evt.data);
          if (
            obj.parent_header.username === user?.attributes?.email &&
            obj.parent_header.msg_id === message.header.msg_id
          ) {
            // if we get a text cell output, we trigger a callback that makes its way back to the original cell, so that we can log it in the cell outputs
            if (
              obj.header.msg_type === "stream" &&
              obj.content.name === "stdout"
            ) {
              console.log(
                `Received text response for code execution with id ${message.header.msg_id}`,
              );
              successCallback({ content: obj.content.text, type: "text" });
            }

            // another case of receiving text
            if (
              obj.header.msg_type === "execute_result" &&
              obj.content.data["text/plain"] &&
              obj.content.data["text/html"]
            ) {
              console.log(
                `Received text response for code execution with id ${message.header.msg_id}`,
              );
              successCallback({
                content: obj.content.data["text/html"],
                type: "html",
              });
            }
            // if the call back is to display_data the response is something we want to show case to user
            if (obj.header.msg_type === "display_data") {
              // if we get an image cell output, we trigger a callback that makes its way to the original cell
              if (
                obj.content &&
                obj.content.data &&
                obj.content.data["image/png"]
              ) {
                console.log(
                  `Received image response for code execution with id ${message.header.msg_id}`,
                );
                successCallback({ content: obj.content, type: "image" });
              } else if (
                obj.content &&
                obj.content.data &&
                obj.content.data["text/html"]
              ) {
                console.log(
                  "Received html response to display bokeh ",
                  message.header.msg_id,
                );
                successCallback({
                  content: obj.content.data["text/html"],
                  type: "bokehAnchorDiv",
                  message_id: obj?.header?.msg_id,
                });
              } else if (
                obj.content &&
                obj.content.data &&
                (obj.content.data["application/vnd.bokehjs_load.v0+json"] ||
                  obj.content.data["application/javascript"])
              ) {
                console.log("Received script to execute bokeh ");
                successCallback({
                  content: obj.content,
                  type: "bokehExecutionScript",
                  message_id: obj?.header?.msg_id,
                });
              } else {
                console.log("Unexpected display response from kernel ", obj);
                completeCallback();
              }
              thunkAPI.dispatch(handleUpdateKernelState("Ready"));
            }

            // if there was an error in the code that was executed, reject the error (this will append the error into the cell's output area)
            else if (obj.header.msg_type === "error") {
              console.log("Recieved error for code execution ", obj.content);
              failureCallback(obj.content);
              thunkAPI.dispatch(handleUpdateKernelState("Ready"));
              return reject([null, obj.content]);
            }

            // when we get to the execute-reply message, the transaction has completed
            else if (obj.header.msg_type === "execute_reply") {
              thunkAPI.dispatch(handleUpdateKernelState("Ready"));
              return resolve([]);
            } else {
              console.log("unhandled response from kernel ", obj);
              completeCallback();
            }
          }
        };
      });
      thunkAPI.fulfillWithValue(response);
    } catch (error) {
      if (socketHolder) {
        thunkAPI.dispatch(handleUpdateKernelState("Ready"));
      } else {
        thunkAPI.dispatch(handleUpdateKernelState("Failed"));
      }
      return thunkAPI.rejectWithValue({ message: "Execute code error" });
    }
  },
);
