import React from "react";
import "./index.css";
import { Outlet } from "react-router-dom";
import QbookHeader from "../../components/header/index";
import { Container } from "@mui/material";
import Footer from "../../components/footer";

type Props = {};
const Layout = (props: Props) => {
  window.onerror = (e) => {
    console.log("Js Error", e);
    return true;
  };
  return (
    <Container
      disableGutters
      maxWidth={false}
      className="layout-parent-container"
    >
      <QbookHeader />
      <div style={{ minHeight: "80vh" }}>
        <Outlet />
      </div>

      <Footer />
    </Container>
  );
};

export default Layout;
