import axiosClient from "../../utils/axiosInstance";

export const attachQuizQuestionsAsync = (
  questionUniqueNames: any,
  fileId: any,
) => {
  const response = axiosClient().post("/attach-quiz-questions", {
    questionUniqueNames,
    fileId,
  });
  return response;
};

export const loadMcqQuestion = (customId: string) => {
  const response = axiosClient().get(`/public/get-quiz-question/${customId}`);
  return response;
};
export const submitQuestionResponseAsync = (payload: any) => {
  const response = axiosClient().post("/public/submit-question-response", {
    ...payload,
  });
  return response;
};

export const retrieveUsersPreviousMcqResponse = ({
  customId,
  courseId,
}: {
  customId: string;
  courseId: string;
}) => {
  const response = axiosClient().get(
    `/questions/mcq/retrieve/user/response/${customId}/${courseId}`,
  );
  return response;
};
export const submitMcqQuestionResponseAsync = (payload: any) => {
  const response = axiosClient().post("/questions/mcq/submit/user/response", {
    ...payload,
  });
  return response;
};

export const getFreeResponseQuestionPublic = (customId: string) => {
  const response = axiosClient().get(
    `/public/questions/free-response/retrieve/${customId}`,
  );
  return response;
};

export const retrieveUsersFreeResponseQuestionAnswerStatus = (
  customId: string,
  courseId: string,
) => {
  const response = axiosClient().post(
    `/questions/free-response/retrieve/user/response/status/${customId}`,
    { courseId: courseId },
  );
  return response;
};

export const submitFreeResponseQuestionResponse = (
  customId: string,
  courseId: string,
  responseText: string,
  submittedFiles: Array<any>,
) => {
  const response = axiosClient().post(
    `/questions/free-response/submit/user/response/${customId}`,
    {
      courseId: courseId,
      responseText: responseText,
      submittedFiles: submittedFiles,
    },
  );
  return response;
};
