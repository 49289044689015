import { Color, PaletteColorOptions, PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    purple?: Partial<Color> | { 450?: string; 350?: string } | undefined;
    log?:
      | PaletteColorOptions
      | { log1: string; log2: string; logForm: string }
      | undefined;
  }
  interface TypeBackground {
    authBg?: string | undefined;
    base?: string | undefined;
    alternate?: string | undefined;
  }
}

export const lightThemePalette: PaletteOptions = {
  mode: "light",
  grey: {
    500: "#AFAFAF",
    400: "#AFAFAF",
    300: "#D9D9D9",
    200: "#868686",
    100: "#AFAFAF",
  },
  purple: {
    500: "#8D36D1",
    450: "#7A2DC7",
    400: "#8428c0",
    300: "#8D36D1",
    350: "#9840D2",
    200: "#d1adf5",
    100: "#8D36D1",
  },
  error: {
    500: "#bd380f",
    400: "#e24312",
    300: "#f1410b",
    200: "#d1adf5",
    100: "#FF4A13",
  },
  log: {
    log1: "#470871",
    log2: "#d70380",
    logForm: "#341548",
  },
  background: {
    default: "#fcfcfc",
    authBg: "var(--auth-linear-bg)",
    paper: "#fcfcfc",
    base: "white",
    alternate: "#f3f6fc",
  },
};

export const darkThemePalette: PaletteOptions = {
  mode: "dark",
  grey: {
    500: "#AFAFAF",
    400: "#AFAFAF",
    300: "#D9D9D9",
    200: "#868686",
    100: "#AFAFAF",
  },
  purple: {
    500: "#8D36D1",
    450: "#7A2DC7",
    400: "#8428c0",
    300: "#8D36D1",
    350: "#9840D2",
    200: "#d1adf5",
    100: "#8D36D1",
  },
  error: {
    500: "#bd380f",
    400: "#e24312",
    300: "#f1410b",
    200: "#d1adf5",
    100: "#FF4A13",
  },
  background: {
    default: "#1f1f1f",
    authBg: "var(--auth-linear-bg)",
  },
};

export const qbraidBasePalette: PaletteOptions = {
  primary: {
    main: "#c430f1",
  },
  secondary: {
    main: "#9b12df",
  },
};
export const qusteamBasePalette: PaletteOptions = {
  primary: {
    main: "#4144bb",
  },
  secondary: {
    main: "#1212df",
  },
};
export const queraBasePalette: PaletteOptions = {
  primary: {
    main: "#6437ff",
  },
  secondary: {
    main: "#8c61ff",
  },
};
