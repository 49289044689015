import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { getCourseTutors } from "../services/tutorServices";

export const loadCourseTutorProfilesAction = createAsyncThunk(
  "tutor/load/tutorProfiles",
  async ({ page }: { page: number }, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> = await getCourseTutors(page);
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "failed to load public course",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to load public course",
      });
    }
  },
);
