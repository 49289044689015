/**
 * @fileOverview This file contains common data that used across all three domain objects,
 */

import React from "react";
import Blogs from "../../../assets/img/Blogs.svg";
import Courses from "../../../assets/img/Courses.svg";
import deviceIcon from "../../../assets/img/quantumDevices.svg";
import jobsIcon from "../../../assets/img/quantumJobs.svg";
import envIcon from "../../../assets/img/environments.svg";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

export const adminNavData = [
  // {
  //   label: "Dashboard",
  //   icon: Dashboard,
  //   url: "/admin",
  // },
  {
    label: "Courses",
    icon: Courses,
    url: "/admin/courses",
  },
  {
    label: "Blogs",
    icon: Blogs,
    url: "/admin/blogs",
  },
];

export const adminDashBoardData = [
  {
    label: "Quantum Devices",
    text: "Click to view Quantum Devices",
    icon: deviceIcon,
    handler: () => {},
  },
  {
    label: "Quantum Jobs",
    text: "Click to View Active Quantum Jobs",
    icon: jobsIcon,
    handler: () => {},
  },
  {
    label: "Environments",
    text: "Click to Environments",
    icon: envIcon,
    handler: () => {},
  },
];

export const adminCourseOverviewData = [
  {
    id: "crs",
    label: "Total Courses",
    value: 0,
    bgColor: "linear-gradient(195deg,#ec407a,#d81b60)",
    icon: React.createElement(MenuBookIcon),
  },
  {
    id: "std",
    label: "Total Students",
    value: 0,
    bgColor: "linear-gradient(195deg,#42424a,#191919)",
    icon: React.createElement(GroupsIcon),
  },
  {
    id: "cls",
    label: "Total Classes",
    value: 0,
    bgColor: "linear-gradient(195deg,#66bb6a,#43a047)",
    icon: React.createElement(SchoolIcon),
  },
  {
    id: "org",
    label: "Total Organizations",
    value: 0,
    bgColor: "linear-gradient(195deg,#49a3f1,#1a73e8)",
    icon: React.createElement(WorkHistoryIcon),
  },
];
