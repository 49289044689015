var ImageRestriction;
(function (ImageRestriction) {
    ImageRestriction["fillArea"] = "fillArea";
    ImageRestriction["fitArea"] = "fitArea";
    ImageRestriction["stencil"] = "stencil";
    ImageRestriction["none"] = "none";
})(ImageRestriction || (ImageRestriction = {}));
var Priority;
(function (Priority) {
    Priority["coordinates"] = "coordinates";
    Priority["visibleArea"] = "visibleArea";
})(Priority || (Priority = {}));

export { ImageRestriction, Priority };
