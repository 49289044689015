import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Bokeh } from "../types/BokehTypes";
import {
  loadBokehPackageAction,
  retrieveBokehAction,
} from "../middleware/bokehMiddleware";

export interface BokehStoreState {
  isLoading: boolean;
  bokehPackageLoading: boolean;
  bokehPackage: any;
  bokehs: Bokeh[];
}
const initialState: BokehStoreState = {
  isLoading: false,
  bokehPackageLoading: false,
  bokehPackage: undefined,
  bokehs: [],
};

const bokehSlice = createSlice({
  name: "bokeh",
  initialState: initialState,
  extraReducers: (builder) => {
    // load bokeh
    builder.addCase(loadBokehPackageAction.pending, (state) => {});
    builder.addCase(
      loadBokehPackageAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(loadBokehPackageAction.rejected, (state, action) => {});
    // retrieve bokeh
    builder.addCase(retrieveBokehAction.pending, (state) => {});
    builder.addCase(
      retrieveBokehAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(retrieveBokehAction.rejected, (state, action) => {});
  },
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    clearIsLoading: (state) => {
      state.isLoading = false;
    },
    handleStartLoading: (state) => {
      state.bokehPackageLoading = true;
    },
    handleFinishLoading: (state, { payload }) => {
      const { bokehPackage } = payload;
      state.bokehPackageLoading = false;
      state.bokehPackage = bokehPackage;
    },
    handleAddBokeh: (state, { payload }) => {
      const { bokeh } = payload;
      state.bokehs = [...state.bokehs, bokeh];
    },
    handleGetIndex: (state, { payload }) => {
      const { uniqueValidator, indexCallback } = payload;
      let uniqueValidators = state.bokehs.map((file) => file.uniqueValidator);
      indexCallback(uniqueValidators.indexOf(uniqueValidator));
    },
  },
});

export const {
  handleStartLoading,
  handleFinishLoading,
  handleAddBokeh,
  handleGetIndex,
  setIsLoading,
  clearIsLoading,
} = bokehSlice.actions;
export default bokehSlice.reducer;
