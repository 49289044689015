import React from "react";
import "./index.css";
import { ThreeCircles } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className="react-loader">
      <ThreeCircles
        height="80"
        width="80"
        color={"var(--accent-clr2)"}
        ariaLabel="grid-loading"
        wrapperClass=""
        visible
      />
      <h1 className="react-loader-text">Loading...</h1>
    </div>
  );
};

export default Loading;
