import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import {
  ACCOUNT_URL,
  DOMAIN_CONFIG_OBJ,
  QBOOK_URL
} from "../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../utils/constants/domainNameValues";
import { useAppSelector } from "../../../reduxStore/hooks";
import { GitHub, LinkedIn, X } from "@mui/icons-material";
import { DiscordIcon } from "./DiscordIcon";

const Footer = () => {
  const { isDark } = useAppSelector((state) => state.theme);
  
  return (
    <Box
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.default
      })}
      cy-data="qbook-footer-container"
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} py={4}>
          <Grid item container xs={12} md={6} spacing={1}>
            <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
              <img
                src={
                  DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                    ? isDark
                      ? DOMAIN_CONFIG_OBJ.darkThemeLogo
                      : DOMAIN_CONFIG_OBJ.lightThemeLogo
                    : DOMAIN_CONFIG_OBJ.lightThemeLogo
                }
                alt={DOMAIN_CONFIG_OBJ.domainName}
                height={DOMAIN_CONFIG_OBJ.fullLogoDimension.height}
                width={DOMAIN_CONFIG_OBJ.fullLogoDimension.width}
                cy-data="qbook-footer-logo"
              />
              <Typography
                color="text.secondary"
                fontSize={14}
                cy-data="qbook-footer-copyright"
              >
                {DOMAIN_CONFIG_OBJ.footer?.copyright}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6} spacing={1}>
            <Grid
              item
              container
              xs={12}
              spacing={2}
              alignItems="center"
              justifyContent={{ md: "flex-end", xs: "flex-start" }}
              zIndex={1}
            >
              <Grid item>
                <a
                  href={`${QBOOK_URL}/terms-and-conditions`}
                  cy-data="qbook-footer-termsOfUse"
                >
                  <Typography fontSize={14} color="text.secondary">
                    {DOMAIN_CONFIG_OBJ.footer?.termsOfUse}
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <a
                  href={`${QBOOK_URL}/privacy-policy`}
                  cy-data="qbook-footer-privacy-policy"
                >
                  <Typography fontSize={14} color="text.secondary">
                    {DOMAIN_CONFIG_OBJ.footer?.privacyPolicy}
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <a
                  href={`${QBOOK_URL}/cookie-policy`}
                  cy-data="qbook-footer-cookie-policy"
                >
                  <Typography fontSize={14} color="text.secondary">
                    {DOMAIN_CONFIG_OBJ.footer?.cookiePolicy}
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ height: 20 }}
                />
              </Grid>
              <Grid
                item
                aria-describedby="social-links"
                cy-data="qbook-footer-social-links"
              >
                <Box display="flex" gap={1}>
                  {DOMAIN_CONFIG_OBJ.socialMedia?.twitterLink && (
                    <IconButton
                      title="X"
                      size="medium"
                      onClick={() => {
                        window.open(
                          DOMAIN_CONFIG_OBJ.socialMedia?.twitterLink,
                          "_blank"
                        );
                      }}
                    >
                      <X fontSize="inherit" />
                    </IconButton>
                  )}
                  {DOMAIN_CONFIG_OBJ.socialMedia?.githubLink && (
                    <IconButton
                      title="Github"
                      size="medium"
                      onClick={() => {
                        window.open(
                          DOMAIN_CONFIG_OBJ.socialMedia?.githubLink,
                          "_blank"
                        );
                      }}
                    >
                      <GitHub fontSize="inherit" />
                    </IconButton>
                  )}
                  {DOMAIN_CONFIG_OBJ.socialMedia?.discordLink && (
                    <IconButton
                      title="Discord"
                      size="medium"
                      onClick={() => {
                        window.open(
                          DOMAIN_CONFIG_OBJ.socialMedia?.discordLink,
                          "_blank"
                        );
                      }}
                    >
                      <DiscordIcon fontSize="inherit" />
                    </IconButton>
                  )}
                  {DOMAIN_CONFIG_OBJ.socialMedia?.linkedinLink && (
                    <IconButton
                      title="LinkedIn"
                      size="medium"
                      onClick={() => {
                        window.open(
                          DOMAIN_CONFIG_OBJ.socialMedia?.linkedinLink,
                          "_blank"
                        );
                      }}
                    >
                      <LinkedIn fontSize="inherit" />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              gap={{ md: 4, xs: 2 }}
              alignItems="center"
              justifyContent={{ md: "flex-end", xs: "flex-start" }}
              cy-data="qbook-footer-redirection-links"
            >
              <Grid item>
                <a href={QBOOK_URL} cy-data="footer-home">
                  <Typography fontSize={14} color="text.secondary">
                    Home
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <a
                  href={DOMAIN_CONFIG_OBJ.docsUrl}
                  target="_blank"
                  rel="noreferrer"
                  cy-data="footer-docs"
                >
                  <Typography fontSize={14} color="text.secondary">
                    Docs
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <a
                  href={DOMAIN_CONFIG_OBJ.careerUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography fontSize={14} color="text.secondary">
                    Careers
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <a href={ACCOUNT_URL} target="_blank" rel="noreferrer">
                  <Typography fontSize={14} color="text.secondary">
                    {DOMAIN_CONFIG_OBJ.domainName}
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ height: 20 }}
                />
              </Grid>
              <Grid item>
                <a
                  href={DOMAIN_CONFIG_OBJ.contactUsUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography fontSize={14} color="text.secondary">
                    Contact Us
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
