import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBlog, loadSubmittedBlogs } from "../services/adminBlogServices";

export const loadSubmittedBlogsAction = createAsyncThunk(
  "adminBlogs/loadBlogs",
  async (_, thunkAPI) => {
    try {
      const response = await loadSubmittedBlogs();
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "failed to get admin blogs",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to get admin blogs",
      });
    }
  },
);

export const deleteBlogsAction = createAsyncThunk(
  "adminBlogs/deleteBlogs",
  async ({ blogId }: { blogId: string }, thunkAPI) => {
    try {
      const response = await deleteBlog(blogId);
      if (response.data && response.status === 200) {
        thunkAPI.dispatch(loadSubmittedBlogsAction());
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "failed to delete admin blogs",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to delete admin blogs",
      });
    }
  },
);
