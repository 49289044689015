import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../reduxStore/hooks";
import CourseCard from "./components/courseCard";
import CoursePreviewCard from "./components/coursePreviewCard";
import GraderUi from "./components/GraderUi";
import { CourseCardLoader } from "./components/CourseLoaders";
import CourseOverView from "./components/CourseOverView";
import { retrieveAdminCoursesAction } from "../../../../../reduxStore/middleware/adminCourseMiddleware";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";

type Props = {};
const MemoizedCourseCard = React.memo(CourseCard);
const SKELETON_COUNT = 2;

const CourseDashBoard = (props: Props) => {
  const dispatch = useAppDispatch();
  useDocumentTitle(`Admin | Courses | ${DOMAIN_CONFIG_OBJ.domainName}`);
  const { courses, loadingAdminCourses, showGrader } = useAppSelector(
    (state) => state.adminCourse
  );
  

  useEffect(() => {
    dispatch(retrieveAdminCoursesAction());
  }, [dispatch]);

  const courseCards = useMemo(() => {
    return courses?.map((course) => (
      <MemoizedCourseCard course={course} key={`${course._id}_${course.courseName}`} />
    ));
  }, [courses]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <CourseOverView />
        {showGrader ? (
          <GraderUi />
        ) : (
          <Grid
            container
            sx={{
              padding: { lg: "1em", md: ".5em", sm: "1em 0", xs: "1em 0" }
            }}
            cy-data="admin-course-container"
          >
            <Grid
              item
              xs={12}
              sx={{ padding: { lg: "1em", md: ".5em", sm: "5px", xs: "5px" } }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: 900,
                  lineHeight: "30px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "var(--text-primary-clr1)"
                }}
                cy-data="admin-course-dashboard-heading"
              >
                View All My Courses
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              lg={5}
              sx={{
                display: "flex",
                gap: "1em",
                alignContent: "flex-start",
                alignItems: "flex-start",
                padding: { lg: "1em", md: ".5em", sm: "5px", xs: "5px" },
                maxHeight: 925,
                overflow: "auto"
              }}
              cy-data="admin-course-cards-container"
            >
              {loadingAdminCourses ? (
                Array.from({ length: SKELETON_COUNT }, (_, index) => (
                  <CourseCardLoader key={`course-card-loader-${index}`} />
                ))
              ) : !loadingAdminCourses && courses?.length === 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "217px",
                    maxHeight: "217px",
                    boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "1em",
                    padding: "1.5em"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "22px",
                      color: "var(--text-gray2)",
                      fontWeight: 700
                    }}
                  >
                    User has no courses created
                  </Typography>
                </Box>
              ) : (
                !loadingAdminCourses && courseCards
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={7}
              lg={7}
              sx={{
                // maxHeight: "700px",
                // overflow: "auto",
                // paddingTop: "60px",
                padding: { lg: "1em", md: ".5em", sm: "5px", xs: "5px" },
                gap: "1em",
                display: "flex",
                alignContent: "flex-start"
              }}
              cy-data="admin-course-preview-container"
            >
              <CoursePreviewCard />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CourseDashBoard;
