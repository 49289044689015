/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../reduxStore/hooks";
import "./index.css";
import SignIn from "./components/signIn";
import SignUp from "./components/signUp";
import ForgetPassword from "./components/forgotPassword";
import SubmitPassword from "./components/submitPassword";
import { Container } from "@mui/material";
import DigitalAtom from "../../../assets/img/digital-atom.png";
import { DOMAIN_CONFIG_OBJ } from "../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../utils/constants/domainNameValues";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export type ModalTypes =
  | "login"
  | "signup"
  | "forget_password"
  | "submit_password";

const StaticOrnament = (
  <>
    <img
      className="ornament_digital-atom pos-left"
      src={DigitalAtom}
      loading="lazy"
      width={212.5}
      height={218}
      alt="Digital Atom"
    />
    <img
      className="ornament_digital-atom pos-right"
      src={DigitalAtom}
      loading="lazy"
      width={212.5}
      height={218}
      alt="Digital Atom 2"
    />
  </>
);

const Auth = () => {
  useDocumentTitle(`Auth | ${DOMAIN_CONFIG_OBJ.domainName}`);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState<ModalTypes>("login");
  const [currentEmail, setCurrentEmail] = useState<string>("");

  const changeCurrentEmail = (value: string) => setCurrentEmail(value);

  const toggleAuthFlowPage = (modalToBeOpened: ModalTypes): void => {
    setOpenModal(modalToBeOpened);
  };

  useEffect(() => {
    if (isAuthenticated) {
      let queryParams = new URLSearchParams(window.location.search);
      let redirectUriFromStorage = window.localStorage.getItem("redirectUri");
      // redirectTo is the url that the user was trying to access before they logged in
      let redirectUri =
        queryParams.get("redirectTo") !== null
          ? queryParams.get("redirectTo")
          : redirectUriFromStorage !== null
            ? "/" + redirectUriFromStorage
            : "/";
      window.location.href = redirectUri || "/";
    }
  }, [isAuthenticated]);

  return (
    <Container
      cy-data="login-container"
      className="auth-container"
      maxWidth={false}
      disableGutters
      aria-label="authentication"
      sx={(theme) => ({
        backgroundImage: theme.palette.background.authBg,
        height: "100dvh",
      })}
    >
      {DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera &&
        StaticOrnament}
      <SignIn
        open={"login" === openModal}
        toggleAuthFlowPage={toggleAuthFlowPage}
      />
      <SignUp
        open={"signup" === openModal}
        toggleAuthFlowPage={toggleAuthFlowPage}
      />
      <ForgetPassword
        open={"forget_password" === openModal}
        toggleAuthFlowPage={toggleAuthFlowPage}
        changeCurrentEmail={changeCurrentEmail}
      />
      <SubmitPassword
        open={"submit_password" === openModal}
        toggleAuthFlowPage={toggleAuthFlowPage}
        email={currentEmail}
      />
    </Container>
  );
};

export default Auth;
