import React from "react";
import "./index.css";

function Button({ className, active = false, children, ...props }) {
  return (
    <button
      type="button"
      className={`image-editor-button ${
        active && "image-editor-button--active"
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
