/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Icon, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import {
  ACCOUNT_URL,
  DOMAIN_CONFIG_OBJ,
} from "../../../../../utils/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../reduxStore/hooks";
import { setShowGrader } from "../../../../../reduxStore/features/adminCourseSlice";

type Props = {};

const NavigationBar = (props: Props) => {
  const { isDark } = useAppSelector((state) => state.theme);
  const [navTabValue, setNavTabValue] = React.useState<string>("/admin");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    switch (true) {
      case pathname.includes("account") && !navTabValue.includes("account"):
        setNavTabValue(DOMAIN_CONFIG_OBJ.admin.navigationBar.tabData[0].url);
        break;
      case pathname.includes("courses") && !navTabValue.includes("courses"):
        setNavTabValue(DOMAIN_CONFIG_OBJ.admin.navigationBar.tabData[1].url);
        break;
      case pathname.includes("blogs") && !navTabValue.includes("blogs"):
        setNavTabValue(DOMAIN_CONFIG_OBJ.admin.navigationBar.tabData[2].url);
        break;
      case pathname === "/admin" && navTabValue !== "/admin":
        setNavTabValue("/admin");
        break;
      default:
        break;
    }
  }, [pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setNavTabValue(newValue);
    if (newValue.includes("account")) {
      window.location.href = ACCOUNT_URL;
      return
    } else if (newValue === "/admin/courses") {
      dispatch(setShowGrader(false));
    }
    navigate(newValue);
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          maxWidth: { xs: 320, sm: 480 },
          color: "black",
        }}
      >
        <Tabs
          value={navTabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable "
          sx={{
            textTransform: "none",
            "& .MuiTabs-indicator": {
              backgroundColor: "var(--text-gray2)",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "var(--text-gray2)",
            },
          }}
          cy-data="admin-navigation-tab-bar"
        >
          {DOMAIN_CONFIG_OBJ.admin.navigationBar.tabData.map(
            (tabValue, index) => {
              return (
                <Tab
                  cy-data="admin-navigation-tab-item"
                  key={index}
                  sx={{ textTransform: "none", padding: "0" }}
                  icon={
                    <Icon
                      sx={{
                        height: "30px",
                        width: "30px",
                        transition: "transform .2s",
                        overflow: "visible",
                        ":hover": {
                          transform: "scale(1.2)",
                        },
                      }}
                    >
                      <img
                        src={tabValue.icon}
                        height="100%"
                        width="100%"
                        style={{
                          objectFit: "contain",
                          filter: isDark ? "invert(1)" : "",
                        }}
                        alt="tabIcons"
                      />
                    </Icon>
                  }
                  label={tabValue.label}
                  value={tabValue.url}
                />
              );
            },
          )}
        </Tabs>
      </Box>
    </Box>
  );
};

export default NavigationBar;
