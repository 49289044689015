import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  deleteBlogsAction,
  loadSubmittedBlogsAction,
} from "../middleware/adminBlogsMiddleware";

export interface AdminBlogsState {
  // TODO : once the schema is finalised for courses, define them here
  loadingAdminBlogs: boolean;
  adminBlogArray: any[];
}
const initialState: AdminBlogsState = {
  loadingAdminBlogs: true,
  adminBlogArray: [],
};

const adminBlogSlice = createSlice({
  name: "adminBlogs",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    // retrieve all blogs user has submitted
    builder.addCase(loadSubmittedBlogsAction.pending, (state) => {
      state.loadingAdminBlogs = true;
    });
    builder.addCase(
      loadSubmittedBlogsAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loadingAdminBlogs = false;
        state.adminBlogArray = action.payload || [];
      },
    );
    builder.addCase(loadSubmittedBlogsAction.rejected, (state, action) => {
      state.loadingAdminBlogs = false;
    });

    // delete the blog user has created
    builder.addCase(deleteBlogsAction.pending, (state) => {});
    builder.addCase(
      deleteBlogsAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(deleteBlogsAction.rejected, (state, action) => {});
  },
});

export default adminBlogSlice.reducer;
