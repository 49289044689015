import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFreeResponseQuestionPublic,
  loadMcqQuestion,
  retrieveUsersFreeResponseQuestionAnswerStatus,
  retrieveUsersPreviousMcqResponse,
  submitFreeResponseQuestionResponse,
  submitMcqQuestionResponseAsync,
  submitQuestionResponseAsync,
} from "../services/quizService";
import { AxiosResponse } from "axios";

export const loadResourceAction = createAsyncThunk(
  "quiz/loadMcq",
  async ({ customId }: { customId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> = await loadMcqQuestion(customId);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "load Mcquiz Failed" });
    }
  },
);

export const submitQuestionResponseAction = createAsyncThunk(
  "quiz/submitQuestionresponse",
  async ({ payload }: { payload: any }, thunkAPI) => {
    try {
      const response = submitQuestionResponseAsync(payload);
      return thunkAPI.fulfillWithValue((await response).data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const submitMcqQuestionResponseAction = createAsyncThunk(
  "quiz/submitMcqQuestionResponse",
  async (
    {
      courseId,
      questionId,
      selectedChoiceIndex,
    }: { courseId: string; questionId: string; selectedChoiceIndex: number },
    thunkAPI,
  ) => {
    try {
      const payload = { courseId, questionId, selectedChoiceIndex };
      const response = await submitMcqQuestionResponseAsync(payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const retrieveUsersPreviousMcqResponseAction = createAsyncThunk(
  "quiz/retrieveUsersPreviousMcqResponse",
  async (
    { customId, courseId }: { customId: string; courseId: string },
    thunkAPI,
  ) => {
    try {
      const response = await retrieveUsersPreviousMcqResponse({
        customId,
        courseId,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const retrieveFreeResponsePublicQuestionAction = createAsyncThunk(
  "quiz/retrievePublicFRQQuestion",
  async ({ payload }: { payload: string }, thunkAPI) => {
    try {
      const response = await getFreeResponseQuestionPublic(payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const retrieveUsersFreeResponseQuestionAnswerStatusAction =
  createAsyncThunk(
    "quiz/retrieveUsersFrqResponse",
    async (
      { courseId, customId }: { courseId: string; customId: string },
      thunkAPI,
    ) => {
      try {
        const response = await retrieveUsersFreeResponseQuestionAnswerStatus(
          customId,
          courseId,
        );
        return thunkAPI.fulfillWithValue(response.data);
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    },
  );

export const submitUsersFreeResponseQuestionAnswerAction = createAsyncThunk(
  "quiz/submitUsersFrqResponse",
  async (
    {
      courseId,
      customId,
      responseText,
      submittedFiles,
    }: {
      courseId: string;
      customId: string;
      responseText: string;
      submittedFiles: Array<any>;
    },
    thunkAPI,
  ) => {
    try {
      const response = await submitFreeResponseQuestionResponse(
        customId,
        courseId,
        responseText,
        submittedFiles,
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
