import { Menu } from "antd";
import React from "react";
import { DOMAIN_CONFIG_OBJ } from "../../../../utils/axiosInstance";
import { useAppSelector } from "../../../../reduxStore/hooks";
import { QBOOK_DOMAIN_VALUES } from "../../../../utils/constants/domainNameValues";
import { useNavigate } from "react-router-dom";

type Props = {};
const NavigationManager = (props: Props) => {
  const navigate = useNavigate();
  const { navigationArray } = useAppSelector((state) => state.navigation);
  const handleClick = () => {
    navigate("/");
  };
  const handleNavClick = (urlKey: string) => {
    if(!urlKey){
      return
    }
    navigate(`/learn/${urlKey}`);
  };
  const { isDark } = useAppSelector((state) => state.theme);
  return (
    <div className="navigation-menus">
      <div className="navigation-logo-container" onClick={() => handleClick()} cy-data="navigation-logo-container">
        <img
          src={
            DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
              ? isDark
                ? DOMAIN_CONFIG_OBJ.darkThemeLogo
                : DOMAIN_CONFIG_OBJ.lightThemeLogo
              : DOMAIN_CONFIG_OBJ.simpleLogo
          }
          className={
            DOMAIN_CONFIG_OBJ.domainName !== QBOOK_DOMAIN_VALUES.quera
              ? "navigation-logo"
              : "navigation-logo-quera"
          }
          alt="navigation"
          cy-data="navigation-logo"
        />
        {DOMAIN_CONFIG_OBJ.domainName !== QBOOK_DOMAIN_VALUES.quera && (
          <span className="navigation-title">
            {DOMAIN_CONFIG_OBJ.domainName}
          </span>
        )}
      </div>
      <div className="navigation-menu-container">
        <Menu
          mode={"horizontal"}
          items={navigationArray}
          style={{
            backgroundColor: "var(--bg-primary-clr1)",
            color: "var(--text-primary-clr1)",
            border: "none",
            maxWidth: "80%",
          }}
          onClick={(e) => handleNavClick(e.key)}
        />
      </div>
    </div>
  );
};

export default NavigationManager;
