import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCourseAsync,
  loadPublicCourses,
  loadUserRegisteredCourses,
  registerUserInToCourse,
} from "../services/learnService";
import Swal from "sweetalert2";
import { AxiosResponse } from "axios";

export const getCourseAction = createAsyncThunk(
  "learn/getCourse",
  async (
    { courseId, fileId }: { courseId: string; fileId: string },
    thunkAPI,
  ) => {
    try {
      const response: AxiosResponse<any, any> = await getCourseAsync(
        courseId,
        fileId,
      );
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({ message: "failed to get course" });
    } catch (error: any) {
      if (error?.response?.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You are not authorized to access this course!",
          allowEscapeKey: false,
        }).then((result) => {
          window.location.href = "/";
        });
        return thunkAPI.rejectWithValue({ message: "Unauthorised access" });
      }
      return thunkAPI.rejectWithValue({ message: "Failed to get course" });
    }
  },
);

export const loadPublicCoursesAction = createAsyncThunk(
  "learn/load/public/courses",
  async ({ page,resultsPerPage }: { page: number,resultsPerPage:number }, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> = await loadPublicCourses(page,resultsPerPage);
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
      return thunkAPI.rejectWithValue({
        message: "failed to load public course",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to load public course",
      });
    }
  },
);

export const registerInToCourseAction = createAsyncThunk(
  "learn/register/public/course",
  async ({ courseId }: { courseId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> =
        await registerUserInToCourse(courseId);
        return thunkAPI.fulfillWithValue(response);
    } catch (error: any) {
      if(error.response){
        if(error.response.status===409){
          return thunkAPI.fulfillWithValue(error.response);
        }
        return thunkAPI.rejectWithValue(error.response);
      }
      return thunkAPI.rejectWithValue({
        message: "Failed to register public course",
      });
    }
  },
);


export const loadUserRegisteredCoursesAction = createAsyncThunk(
  "learn/load/courses/user-registered",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> =
        await loadUserRegisteredCourses();
      if (response.data && response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data.courses || []);
      }
      return thunkAPI.rejectWithValue({
        message: "Failed to load user registered courses",
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: "Failed to load user registered courses",
      });
    }
  },
);
