import React, { memo } from "react";
import {
  Box,
  Skeleton,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// Constants
const CARD_HEIGHT = "217px";
const SKELETON_WIDTH = 150;
const TABLE_ROWS = 3;

// Reusable styles
const commonCellStyle = {
  fontWeight: "400",
  fontSize: "15px",
};

type CardLoaderProp = {
  selectedView?: boolean;
};

const CourseCardLoader = memo(({ selectedView }: CardLoaderProp) => {
  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "100%",
          height: CARD_HEIGHT,
          boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1em",
          padding: "1.5em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Skeleton width="50%" height={50} />
          {selectedView && <Skeleton width="100px" height={20} />}
        </Box>
        <Skeleton width="100%" height={30} />
        <Skeleton width="100%" height={30} sx={{ marginTop: "50px" }} />
        <Skeleton width={100} height={60} sx={{ alignSelf: "flex-end" }} />
      </Box>
    </Slide>
  );
});

type TableLoaderTypeProp = {
  graderTable?: boolean;
};

const CourseUserTableLoader = memo(({ graderTable }: TableLoaderTypeProp) => {
  return (
    <TableContainer sx={{ overflowX: "hidden !important" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {[...Array(3)].map((_, index) => (
              <TableCell key={index} align="left" sx={commonCellStyle}>
                <Skeleton width={50} />
              </TableCell>
            ))}
            <TableCell align="left" sx={{ maxWidth: "150px" }}>
              {!graderTable && <Skeleton width={100} />}
            </TableCell>
            <TableCell align="right" sx={commonCellStyle}>
              {!graderTable && (
                <Stack flexDirection="row" gap={0.1} alignItems="center">
                  <Skeleton width={50} />
                  <Skeleton width={50} />
                </Stack>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            minWidth: 400,
            "& .MuiTableRow-root:hover": {
              backgroundColor: "#8D36D126",
              cursor: "pointer",
            },
          }}
        >
          {[...Array(TABLE_ROWS)].map((_, index) => (
            <TableRow key={index} sx={{ height: "51px !important" }}>
              {[...Array(3)].map((_, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  align="left"
                  sx={cellIndex === 2 ? { ...commonCellStyle, fontSize: "11px" } : {}}
                >
                  <Skeleton
                    height={cellIndex === 2 ? "1rem" : "1.5rem"}
                    width={SKELETON_WIDTH}
                  />
                </TableCell>
              ))}
              <TableCell align="left" sx={{ paddingRight: 0 }}>
                <Skeleton variant="rounded" height={20} width={SKELETON_WIDTH} />
              </TableCell>
              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export { CourseCardLoader, CourseUserTableLoader };
