import axios from "axios";
import {
  DEFAULT_KERNEL_NAME,
  ENTER_PRISE_GATE_WAY,
} from "../../utils/axiosInstance";

export const BASE_URL = `https://${ENTER_PRISE_GATE_WAY}`;
export const WEBSOCKET_URL = `wss://${ENTER_PRISE_GATE_WAY}`;
export const WebSocketClient = require("websocket").w3cwebsocket;
export const KERNEL_NAME = DEFAULT_KERNEL_NAME;
// the number of retries to get the correct EG

export const NUMBER_RETRIES = 20;

export const startKernelAsync = (kernel_type: any) => {
  // let env = {'KERNEL_USERNAME': user.attributes.email}
  // we slightly underprovision CPU so that we have space per node to burst into.
  let env = {
    KERNEL_CPUS: "0.12",
    KERNEL_MEMORY: "0.6G",
    KERNEL_MEMORY_LIMIT: "0.6G",
  };
  const response = axios.post(`${BASE_URL}/api/kernels`, {
    name: kernel_type,
    env,
  });
  return response;
};

export const getKernelAsync = (kernel_id: any) => {
  const response = axios.get(`${BASE_URL}/api/kernels/${kernel_id}`);
  return response;
};

export const listKernelAsync = () => {
  const response = axios.get(`${BASE_URL}/api/kernels`);
  return response;
};

export const shutdownKernelAsync = (kernel_id: any) => {
  const response = axios.delete(`${BASE_URL}/api/kernels/${kernel_id}`);
  return response;
};

export const restartKernelAsync = (kernel_id: any) => {
  const response = axios.post(`${BASE_URL}/api/kernels/${kernel_id}/restart`);
  return response;
};

export const interruptKernelAsync = (kernel_id: any) => {
  const response = axios.post(`${BASE_URL}/api/kernels/${kernel_id}/interrupt`);
  return response;
};

export const createWebsocketAsync = (kernelId: any) => {
  return new Promise(async (resolve, reject) => {
    let client = new WebSocketClient(
      `${WEBSOCKET_URL}/api/kernels/${kernelId}/channels`,
    );

    client.onopen = () => {
      console.log(`Websocket connected to kernel with id ${kernelId}`);
      resolve(client);
    };

    client.onerror = async (error: any) => {
      reject(error);
    };
  });
};
