import React, { SyntheticEvent, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Email } from "@mui/icons-material";
import { ModalTypes } from "../..";
import { useAppDispatch } from "../../../../../reduxStore/hooks";
import { UserForgotPasswordAction } from "../../../../../reduxStore/middleware/authMiddleware";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../../../utils/constants/domainNameValues";

interface IForgetPasswordProps {
  open: boolean;
  toggleAuthFlowPage: (modalToBeOpened: ModalTypes) => void;
  changeCurrentEmail: (email: string) => void;
}

const ForgetPassword = ({
  open,
  toggleAuthFlowPage,
  changeCurrentEmail,
}: IForgetPasswordProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const verifyEmail = (email: string | undefined): boolean => {
    if (!email) return false;
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(email);
  };

  const forgetPasswordSubmitHandler = (
    event: SyntheticEvent<HTMLFormElement, SubmitEvent>,
  ) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    const email = formData.get("email")?.toString();
    const isEmailCorrect = verifyEmail(email);

    if (!isEmailCorrect) {
      setErrorMessage("Please enter a valid email");
      return;
    }

    setErrorMessage("");
    if (email) {
      try {
        dispatch(UserForgotPasswordAction(email)).then((response) => {
          if (response) {
            changeCurrentEmail(email);
            toggleAuthFlowPage("submit_password");
          }
        });
      } catch (err) {
        setErrorMessage("Something went wrong!");
        console.log(err);
      }
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      hideBackdrop
      PaperProps={{
        sx: {
          backgroundColor: "var(--auth-form-bg)",
          width: fullScreen ? "100%" : 480,
          p: fullScreen ? "1em" : "1em 3em",
          borderRadius: "8px",
          boxShadow: "0px 1px 15px rgba(199, 199, 199, 0.5)",
          color: theme.palette.common.white,
        },
      }}
      aria-label="forget password"
      cy-data="forget-password"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            className={`login-logo ${
              DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam
                ? "login-logo-qusteam"
                : DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qbraid
                  ? "login-logo-qbraid"
                  : ""
            }`}
            src={DOMAIN_CONFIG_OBJ.darkThemeLogo}
            alt="qbook logo"
            cy-data="login-logo"
          />
        </Grid>
      </Grid>
      <DialogTitle textAlign="center" fontSize={28} color="#f5f5f5">
        Reset Password
      </DialogTitle>
      <DialogContent>
        <form spellCheck="false" onSubmit={forgetPasswordSubmitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                fontSize={16}
                textAlign="center"
                color={theme.palette.grey[500]}
              >
                Forgot your password? Send a reset code to your mail
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                type="text"
                variant="outlined"
                placeholder="Enter your email address"
                cy-data="email"
                required
                fullWidth
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email sx={{ color: "#2E3338" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="qbookAuth"
                color="authSubmit"
                size="large"
                type="submit"
                cy-data="login-submit"
                fullWidth
              >
                Send Reset Code
              </Button>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Button
                variant="qbookAuth"
                color="authCancel"
                size="large"
                onClick={() => toggleAuthFlowPage("login")}
                cy-data="login-submit"
                fullWidth
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ForgetPassword;
