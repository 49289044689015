/**
 * Component for the navigation bar in the image editor.
 *
 * @module Navigation
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - The function to handle mode change.
 * @param {Function} props.onUpload - The function to handle image upload.
 * @param {Function} props.onDownload - The function to handle image download.
 * @param {string} props.mode - The current mode of the image editor.
 * @returns {JSX.Element} The navigation bar component.
 * @copyright (C) 2024 qBraid Development Team
 */

import React, { useRef } from "react";
import { Box, Button as MuiButton, Typography } from "@mui/material";
import {
  ContrastRounded,
  CropRounded,
  FileDownloadRounded,
  FilterTiltShift,
  FlareRounded,
  FormatColorResetRounded,
  UploadFileRounded,
} from "@mui/icons-material";
import Button from "../button";
import "./index.css";
import { useAppDispatch } from "../../../../../../../../../reduxStore/hooks";
import { handleSnackbarOpen } from "../../../../../../../../../reduxStore/features/snackbarSlice";

const UPLOAD_SIZE_LIMIT = 1000;

function Navigation({ onChange, onUpload, onDownload, mode }) {
  const dispatch = useAppDispatch();
  const setMode = (modes) => () => {
    onChange?.(modes);
  };

  const inputRef = useRef(null);

  const onUploadButtonClick = () => {
    inputRef.current?.click();
  };

  const onLoadImage = (event) => {
    // Reference to the DOM input element
    const { files } = event.target;

    // Ensure that you have a file before attempting to read it
    if (files && files[0] && files[0].size <= UPLOAD_SIZE_LIMIT * 1024) {
      if (onUpload) {
        onUpload(URL.createObjectURL(files[0]));
      }
    } else {
      dispatch(
        handleSnackbarOpen({
          message: "File size is too big! Image size should be less than 1MB",
          severity: "warning",
        }),
      );
    }
    // Clear the event target value to give the possibility to upload the same image:
    event.target.value = "";
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={1}
      height={84}
      paddingX={2}
      paddingY={1}
      borderTop="1px solid text.secondary"
      position="relative"
    >
      <Typography
        color="white"
        fontFamily="Inter"
        fontSize={14}
        textTransform="capitalize"
        position="absolute"
        sx={{
          opacity: 0.8,
          top: "-25%",
          left: "50%",
          transform: "translateX(-50%)",
          textShadow: "1px 0px 3px rgba(0,0,0,0.9)",
          userSelect: "none",
        }}
      >
        {mode}
      </Typography>
      <MuiButton
        variant="outlined"
        startIcon={<UploadFileRounded />}
        onClick={onUploadButtonClick}
        sx={{
          textTransform: "unset",
          borderRadius: "100vw",
          color: "var(--brand-color-3)",
          borderColor: "var(--brand-color-3)",
        }}
      >
        Browse
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onLoadImage}
          className="image-editor-navigation__upload-input"
        />
      </MuiButton>
      <div className="image-editor-navigation__buttons">
        <Button
          className="image-editor-navigation__button"
          active={mode === "crop"}
          onClick={setMode("crop")}
          title="Crop"
        >
          <CropRounded />
        </Button>
        <Button
          className="image-editor-navigation__button"
          active={mode === "saturation"}
          onClick={setMode("saturation")}
          title="Saturation"
        >
          <FormatColorResetRounded />
        </Button>
        <Button
          className="image-editor-navigation__button"
          active={mode === "brightness"}
          onClick={setMode("brightness")}
          title="Brightness"
        >
          <FlareRounded />
        </Button>
        <Button
          className="image-editor-navigation__button"
          active={mode === "contrast"}
          onClick={setMode("contrast")}
          title="Contrast"
        >
          <ContrastRounded />
        </Button>
        <Button
          className="image-editor-navigation__button"
          active={mode === "hue"}
          onClick={setMode("hue")}
          title="Hue"
        >
          <FilterTiltShift />
        </Button>
      </div>
      <MuiButton
        variant="outlined"
        startIcon={<FileDownloadRounded />}
        onClick={onDownload}
        sx={{
          textTransform: "unset",
          borderRadius: "100vw",
          color: "var(--brand-color-3)",
          borderColor: "var(--brand-color-3)",
        }}
      >
        Save
      </MuiButton>
    </Box>
  );
}

export default Navigation;
