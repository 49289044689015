import React from "react";
import { getPreviewStyle } from "react-advanced-cropper";
import { AdjustableImage } from "../adjustableImage";

const AdjustablePreviewBackground = ({
  className,
  cropper,
  crossOrigin,
  size,
  brightness = 0,
  saturation = 0,
  hue = 0,
  contrast = 0,
}) => {
  const state = cropper.getState();
  const transitions = cropper.getTransitions();
  const image = cropper.getImage();

  const style =
    image && state && size
      ? getPreviewStyle(image, state, size, transitions)
      : {};

  return (
    <AdjustableImage
      src={image?.src}
      crossOrigin={crossOrigin}
      brightness={brightness}
      saturation={saturation}
      hue={hue}
      contrast={contrast}
      className={className}
      style={style}
    />
  );
};

export default AdjustablePreviewBackground;
