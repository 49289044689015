import { CookieValues } from "../../types/domainType";
import axiosClient from "../../utils/axiosInstance";

interface IConcent {
    [key: string]: CookieValues;
}

export const updateCookieConsent = async (
    consent: IConcent
): Promise<string | undefined> => {
    try {
        const response = await axiosClient().put(
            "/user/update-cookie-consent",
            { consent }
        );
        if (response) {
            if (response.status === 200) return "success";
            else return "failure";
        } else throw new Error(response);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Something went wrong", err);
    }
};
