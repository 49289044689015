import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./features/rootReducer";
import { enableMapSet } from "immer";

enableMapSet();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
