/**
 * MUI theme object
 * @module src/utils/MUITheme/theme.js
 * @description This module exports the light and dark theme objects for the MUI theme provider. the pallets are dynamic from the config file
 */

import { createTheme } from "@mui/material/styles";
import { darkThemePalette, lightThemePalette } from "./palette";
import { typography } from "./typography";
import { DOMAIN_CONFIG_OBJ } from "../axiosInstance";

export const lightTheme = createTheme({
  palette: { ...lightThemePalette, ...DOMAIN_CONFIG_OBJ.MUI.palette },
  typography: typography,
  components: DOMAIN_CONFIG_OBJ.MUI.component,
});

export const darkTheme = createTheme({
  palette: { ...darkThemePalette, ...DOMAIN_CONFIG_OBJ.MUI.palette },
  typography: typography,
  components: DOMAIN_CONFIG_OBJ.MUI.component,
});
