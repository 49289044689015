import {
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import "./courseTable.css";
import { BorderLinearProgress } from "../../../../../components/styledComponents/ProgressBar";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../../reduxStore/hooks";
import CustomSearchField from "../../../../../components/styledComponents/customTextField";
import {
  CourseUser,
  addCourseIdInToRefreshingCourses,
  setSelectedUser,
  setShowGrader
} from "../../../../../../reduxStore/features/adminCourseSlice";

const MemoizedTableCell = React.memo(TableCell);
const MemoizedTypography = React.memo(Typography);

type PropsTypes = {
  handleRefresh?: any;
  itemId?: any;
  graderTable?: boolean;
};
const UserTableList = ({ handleRefresh, itemId, graderTable }: PropsTypes) => {
  const dispatch = useAppDispatch();
  const { showGrader } = useAppSelector((state) => state.adminCourse);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = useState<string>("reset");
  const [rotateRefresh, setRotateRefresh] = useState<boolean>(false);
  const {
    refreshingCourses,
    selectedCourse,
    selectedCourseUserList,
    selectedUser
  } = useAppSelector((state) => state.adminCourse);

  const sortFiles = useCallback((items: Array<CourseUser>, sortBy: string) => {
    if (sortBy === "reset") return items;

    const sortConfig: { [key: string]: [string[], "asc" | "desc"] } = {
      ascending: [["firstName", "lastName", "userName"], "asc"],
      decending: [["firstName", "lastName", "userName"], "desc"],
      progress_most: [["progress"], "desc"],
      progress_least: [["progress"], "asc"]
    };

    const [sortKeys, sortOrder] = sortConfig[sortBy] || [[], "asc"];
    return _.orderBy(items, sortKeys, [sortOrder]);
  }, []);

  const searchUsers = useCallback(
    (usersList: Array<CourseUser>, query: string) => {
      if (query.length < 1) return usersList;

      const lowerQuery = query.toLowerCase();
      const regex = new RegExp(lowerQuery, "i");

      return usersList.filter(
        (user: CourseUser) =>
          regex.test(user.firstName || "") ||
          regex.test(user.lastName || "") ||
          regex.test(user.userName || "")
      );
    },
    []
  );

  const userData = useMemo(() => {
    let filteredItems = selectedCourseUserList || [];
    filteredItems = sortFiles(filteredItems, selectedSort);
    return searchUsers(filteredItems, searchQuery);
  }, [
    selectedSort,
    searchQuery,
    selectedCourseUserList,
    sortFiles,
    searchUsers
  ]);

  const handleSortMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleSortMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSortBy = useCallback((sortBy: string) => {
    setSelectedSort(sortBy);
  }, []);

  const refresh = useCallback(() => {
    setRotateRefresh(true);
    handleRefresh();
    setTimeout(() => {
      setRotateRefresh(false);
    }, 1500);
  }, [handleRefresh]);

  const handleUserRowSelection = useCallback(
    (user: any) => {
      dispatch(setSelectedUser(user));
      if (!showGrader) {
        dispatch(
          addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id })
        );
        dispatch(setShowGrader(true));
      }
    },
    [dispatch, showGrader, selectedCourse._id]
  );

  const renderTableHeader = useMemo(
    () => (
      <TableHead cy-data="admin-course-user-table-head">
        <TableRow>
          <MemoizedTableCell
            align="left"
            cy-data="admin-course-user-table-head-cell"
          >
            <MemoizedTypography sx={{ fontWeight: "700", fontSize: "19px" }}>
              Users
            </MemoizedTypography>
          </MemoizedTableCell>
          <MemoizedTableCell
            align="left"
            sx={{ fontWeight: "400px", fontSize: "15px" }}
            cy-data="admin-course-user-table-head-cell"
          >
            Progress
          </MemoizedTableCell>
          <MemoizedTableCell
            align="left"
            sx={{ fontWeight: "400px", fontSize: "15px" }}
            cy-data="admin-course-user-table-head-cell"
          >
            {graderTable ? "Score" : "Last Signin"}
          </MemoizedTableCell>
          <MemoizedTableCell
            align={!graderTable ? "left" : "center"}
            sx={{ maxWidth: "150px" }}
            cy-data="admin-course-user-table-head-cell"
          >
            {!graderTable ? (
              <CustomSearchField
                handler={setSearchQuery}
                value={searchQuery}
                placeHolder="Search For User"
                cyData="admin-course-user-table-search-input"
              />
            ) : (
              "Role"
            )}
          </MemoizedTableCell>
          <MemoizedTableCell
            align="right"
            sx={{ fontWeight: "400px", fontSize: "15px" }}
            cy-data="admin-course-user-table-head-cell"
          >
            {!graderTable && (
              <Stack flexDirection="row" gap={0.1} alignItems="center">
                <IconButton
                  size="small"
                  className={rotateRefresh ? "rotate-refresh-icon" : ""}
                  onClick={refresh}
                  cy-data="admin-course-user-table-head-refresh-btn"
                >
                  <CachedIcon titleAccess="Refresh Course" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={handleSortMenuOpen}
                  title="Sort"
                  id="admin-user-table-sort-button"
                  aria-controls={Boolean(anchorEl) ? "admin-user-table-sort-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                >
                  <TuneIcon titleAccess="Sort by" />
                </IconButton>
                <Menu
                  id="admin-user-table-sort-menu"
                  anchorEl={anchorEl}
                  transformOrigin={{ horizontal: "left", vertical: "top" }}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  open={Boolean(anchorEl)}
                  onClose={handleSortMenuClose}
                  onClick={handleSortMenuClose}
                  MenuListProps={{ "aria-labelledby": "admin-user-table-sort-button" }}
                >
                  <MemoizedTypography
                    fontSize={10}
                    fontWeight={600}
                    pl={1}
                    color="text.secondary"
                  >
                    Sort by
                  </MemoizedTypography>
                  <MenuItem
                    onClick={() => handleSortBy("ascending")}
                    sx={{ fontSize: "14px" }}
                  >
                    A → Z
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSortBy("decending")}
                    sx={{ fontSize: "14px" }}
                  >
                    Z → A
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSortBy("progress_most")}
                    sx={{ fontSize: "14px" }}
                  >
                    Most Progress
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSortBy("progress_least")}
                    sx={{ fontSize: "14px" }}
                  >
                    Least Progress
                  </MenuItem>
                </Menu>
              </Stack>
            )}
          </MemoizedTableCell>
        </TableRow>
      </TableHead>
    ),
    [
      graderTable,
      searchQuery,
      setSearchQuery,
      rotateRefresh,
      refresh,
      anchorEl,
      handleSortMenuOpen,
      handleSortMenuClose,
      handleSortBy
    ]
  );

  const renderTableBody = useMemo(
    () => (
      <TableBody
        sx={(theme) => ({
          minWidth: 600,
          "& .MuiTableRow-root:hover": {
            cursor: "pointer",
            backgroundColor: "var(--admin-table-row-hover)"
          }
        })}
      >
        {userData.length > 0 ? (
          userData.map((user: any, index) => (
            <TableRow
              key={index}
              sx={{ height: "51px !important" }}
              onClick={() => handleUserRowSelection(user)}
              selected={user.frontEndId === selectedUser?.frontEndId}
              cy-data="admin-course-user-table-row"
            >
              <MemoizedTableCell align="left">
                {refreshingCourses?.includes(itemId) ? (
                  <Skeleton height="1.5rem" width="inherit" />
                ) : (
                  <MemoizedTypography
                    sx={{ fontWeight: "600", fontSize: "13px" }}
                    cy-data="admin-course-user-table-user-name"
                  >
                    {user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : user.userName || "Not Available"}
                  </MemoizedTypography>
                )}
              </MemoizedTableCell>
              <MemoizedTableCell align="left">
                {refreshingCourses?.includes(itemId) ? (
                  <Skeleton height="1.5rem" width="inherit" />
                ) : (
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (user.progress / selectedCourse?.courseProgressPoints) *
                      100
                    }
                    cy-data="admin-course-user-table-user-progress"
                  />
                )}
              </MemoizedTableCell>
              <MemoizedTableCell
                align="left"
                sx={{ fontWeight: "400", fontSize: "11px" }}
              >
                {refreshingCourses?.includes(itemId) ? (
                  <Skeleton height="1rem" width="inherit" />
                ) : graderTable ? (
                  `${
                    isNaN(user.progress)
                      ? "0.00"
                      : parseFloat(user.progress).toFixed(2)
                  }/${selectedCourse?.courseProgressPoints || 100}`
                ) : (
                  moment(user.lastActivity).fromNow(true)
                )}
              </MemoizedTableCell>
              <MemoizedTableCell align="center" sx={{ paddingRight: 0 }}>
                {refreshingCourses?.includes(itemId) ? (
                  <Skeleton
                    variant="rounded"
                    height={graderTable ? 50 : 20}
                    width={graderTable ? 100 : "inherit"}
                  />
                ) : (
                  <MemoizedTypography
                    // variant="contained"
                    className={`role-button ${
                      user?.role?.toLowerCase() || "student"
                    }`}
                    sx={{
                      minWidth: graderTable ? "inherit" : "150px",
                      padding: graderTable ? "15px" : "0",
                      maxHeight: graderTable ? "45px" : "30px",
                      textTransform: "none",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap"
                    }}
                    cy-data="admin-course-user-table-user-role-btn"
                  >
                    {user.role === "TeachingAssistant"
                      ? "Teaching Assistant"
                      : user.role || "Student"}
                  </MemoizedTypography>
                )}
              </MemoizedTableCell>
              <MemoizedTableCell>{}</MemoizedTableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <MemoizedTableCell colSpan={5} align="center">
              {refreshingCourses?.includes(itemId) ? (
                <Skeleton height="1.5rem" width={150} />
              ) : (
                <MemoizedTypography
                  variant="h6"
                  cy-data="admin-course-table-no-user-found-message"
                >
                  No user found..
                </MemoizedTypography>
              )}
            </MemoizedTableCell>
          </TableRow>
        )}
      </TableBody>
    ),
    [
      userData,
      refreshingCourses,
      itemId,
      graderTable,
      selectedCourse,
      handleUserRowSelection,
      selectedUser
    ]
  );

  return (
    <TableContainer
      sx={(theme) => ({
        // overflowX: 'hidden !important'
        maxHeight: 660
      })}
      cy-data="admin-course-user-table-container"
    >
      <Table stickyHeader aria-label="simple table">
        {renderTableHeader}
        {renderTableBody}
      </Table>
    </TableContainer>
  );
};

export default React.memo(UserTableList);
