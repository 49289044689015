import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 5,
  // backgroundColor:'var(--admin-linear-progress-track)',
  "& .MuiLinearProgress-bar": {
    backgroundColor: "var(--admin-linear-progress-bar)",
  },
}));
