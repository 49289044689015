import { combineReducers } from "@reduxjs/toolkit";
import themeSlice from "./themeSlice";
import authSlice from "./authSlice";
import kernalSlice from "./kernalSlice";
import blogSlice from "./blogSlice";
import bokehSlice from "./bokehSlice";
import learnSlice from "./learnSlice";
import quizSlice from "./quizSlice";
import navigationSlice from "./navigationSlice";
import snackbarSlice from "./snackbarSlice";
import adminTabSlice from "./adminTabSlice";
import adminCourseSlice from "./adminCourseSlice";
import tutorSlice from "./tutorSlice";
import adminBlogSlice from "./adminBlogSlice";

const rootReducer = combineReducers({
  theme: themeSlice,
  navigation: navigationSlice,
  auth: authSlice,
  kernel: kernalSlice,
  blogs: blogSlice,
  bokeh: bokehSlice,
  learn: learnSlice,
  quiz: quizSlice,
  snackbar: snackbarSlice,
  adminTab: adminTabSlice,
  adminCourse: adminCourseSlice,
  adminBlogs: adminBlogSlice,
  tutor: tutorSlice,
});
export default rootReducer;
