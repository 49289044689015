import React from "react";
import "./index.css";
import AdminHeader from "./components/header";
import { Outlet } from "react-router-dom";
import UnauthorizedUI from "./components/unauthorizedUi/unauthorizedUi";
import { COURSE_ADMIN_PERMISSION } from "../../../utils/constants/permissionNodes";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
type Props = {};

const AdminLayout = (props: Props) => {
  const { userData, isLoading: userLoading } = useSelector(
    (state: any) => state.auth
  );
  return (
    <div className="admin-parent-container">
      {userLoading ? (
        <Loading />
      ) : !userLoading &&
        userData?.permissionsNodes?.includes(COURSE_ADMIN_PERMISSION) ? (
        <>
          <AdminHeader />
          {/* <NavigationBar /> */}
          <Outlet />
        </>
      ) : (
        <UnauthorizedUI />
      )}
    </div>
  );
};

export default AdminLayout;
