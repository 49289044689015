import 'tslib';
import { getDefaultTransforms, getDefaultVisibleArea, getDefaultCoordinates } from '../service/helpers.js';
import { Priority } from '../types/index.js';
import { setCoordinates, SetCoordinatesMode } from './setCoordinates.js';
import { setVisibleArea } from './setVisibleArea.js';

function createState(options, settings) {
    var _a, _b, _c, _d, _e;
    var boundary = options.boundary, image = options.image;
    var state = {
        boundary: {
            width: boundary.width,
            height: boundary.height,
        },
        imageSize: {
            width: image.width,
            height: image.height,
        },
        transforms: {
            rotate: ((_a = image.transforms) === null || _a === void 0 ? void 0 : _a.rotate) || 0,
            flip: {
                horizontal: ((_c = (_b = image.transforms) === null || _b === void 0 ? void 0 : _b.flip) === null || _c === void 0 ? void 0 : _c.horizontal) || false,
                vertical: ((_e = (_d = image.transforms) === null || _d === void 0 ? void 0 : _d.flip) === null || _e === void 0 ? void 0 : _e.vertical) || false,
            },
        },
        visibleArea: null,
        coordinates: null,
    };
    if (settings.defaultTransforms) {
        state.transforms = getDefaultTransforms(state, settings);
    }
    if (settings.priority === Priority.visibleArea) {
        state = setVisibleArea(state, settings, getDefaultVisibleArea(state, settings), false);
        state = setCoordinates(state, settings, getDefaultCoordinates(state, settings), SetCoordinatesMode.limit);
    }
    else {
        state = setCoordinates(state, settings, getDefaultCoordinates(state, settings), SetCoordinatesMode.unsafe);
        state = setVisibleArea(state, settings, getDefaultVisibleArea(state, settings), true);
    }
    return state;
}

export { createState };
