import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Grid } from "@mui/material";
import Cookies from "universal-cookie";
import EnlargedForm from "./EnlargedForm";
import MinimizedForm from "./MinimizedForm";

import { DOMAIN_CONFIG_OBJ } from "../../../utils/axiosInstance";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/hooks";
import { CookieState, ICookieProps } from "./types";
import { ConsentTypes } from "../../../types/domainType";
import { updateCookieConsent } from "../../../reduxStore/services/cookieService";
import { handleSnackbarOpen } from "../../../reduxStore/features/snackbarSlice";

const CONSENTCOOKIE = "consent-cookie";

const INITIALSTATE = DOMAIN_CONFIG_OBJ.cookies_consent.reduce<CookieState>(
    (acc, cookie) => {
        acc[cookie.id] = cookie.default_value;
        return acc;
    },
    {}
);

const isLocalHost =
    (window.location.href.match("localhost:300") || []).length === 1;
const domain = !isLocalHost ? DOMAIN_CONFIG_OBJ.domain : "localhost";

const CookieConsent = ({
    positionX = "left",
    positionY = "bottom",
    open,
    close = () => {},
}: ICookieProps) => {
    const [decisionMade, setDecisionMade] = useState<boolean>(true); // start with true to avoid flashing
    const [managePreference, setManagePreference] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [preferedCookies, setPreferedCookies] =
        useState<CookieState>(INITIALSTATE);
    const { userData } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const metadata = userData?.metadata;

    const cookies = useMemo(() => new Cookies(), []);

    useEffect(() => {
        if (metadata) {
            const cookieConsentGiven = metadata?.cookieConsentGiven || false;
            let cookieObject = {};
            if (cookieConsentGiven) {
                cookieObject = metadata?.cookieConsentObject || {};
            }

            if (
                cookies.get(CONSENTCOOKIE) !== undefined &&
                cookieConsentGiven
            ) {
                // if CONSENTCOOKIE is PRESENT & consent from api is TRUE, then we dont need to pop-put cookie card
                setDecisionMade(true);
                setPreferedCookies(cookieObject);
                if (open) {
                    close();
                }
            } else if (
                cookies.get(CONSENTCOOKIE) !== undefined &&
                !cookieConsentGiven
            ) {
                // if CONSENTCOOKIE is PRESENT & consent from api is FALSE, then we need to pop-put cookie card
                cookies.remove(CONSENTCOOKIE);
                setDecisionMade(false);
            } else if (!cookies.get(CONSENTCOOKIE) && cookieConsentGiven) {
                // if CONSENTCOOKIE is NOT PRESENT & consent from api is TRUE, then we dont need need to pop-put cookie card
                // rather, update the current CONSENTCOOKIE
                setDecisionMade(true);
                if (open) {
                    close();
                }
                setPreferedCookies(cookieObject);
                // set cookieObject with values from api
                handleCookie(cookieObject);
            } else {
                setDecisionMade(false);
            }
        }
        // eslint-disable-next-line
    }, [metadata]);

    useEffect(() => {
        if (open) {
            setDecisionMade(false);
        }
    }, [open]);

    const gtag = (...args: any[]) => {
        // Send Google Analytics event tracking for cookie consent dialog
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(...args);
    };

    const sendConsent = useCallback((consent) => {
        gtag("consent", "default", consent);
    }, []);

    const togglePreference = () => setManagePreference((prev) => !prev);

    const handleCookie = (consent: ConsentTypes) => {
        cookies.set(CONSENTCOOKIE, consent, {
            expires: new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
            ),
            path: "/",
            domain: domain,
        });
        sendConsent(consent);
        setDecisionMade(true);
        if (open) {
            close();
        }
    };

    const cookieConsentUpdateUser = (consent: CookieState) => {
        updateCookieConsent(consent)
            .then((res) => {
                if (res === "success") {
                    handleCookie(consent);
                }
                setLoading(false);
            })
            .catch(() => {
                dispatch(
                    handleSnackbarOpen({
                        message: "Something went wrong!",
                        severity: "error",
                    })
                );
                setLoading(false);
            });
    };

    const grantAllConsents = (consentStatus: CookieState) =>
        Object.keys(consentStatus).reduce<CookieState>((acc, key) => {
            acc[key] = "granted";
            return acc;
        }, {});

    const handleAcceptAll = async () => {
        setLoading(true);
        const consent = grantAllConsents(INITIALSTATE);
        cookieConsentUpdateUser(consent);
    };

    const handleRejectAll = async () => {
        setLoading(true);
        const consent = INITIALSTATE;
        cookieConsentUpdateUser(consent);
    };

    const handleSavePreference = async () => {
        setLoading(true);
        let consent = {};
        Object.entries(preferedCookies).forEach((preference) => {
            consent = { ...consent, [preference[0]]: preference[1] };
        });
        cookieConsentUpdateUser(consent);
    };

    const handleChoosePreference = (option: keyof ConsentTypes) =>
        setPreferedCookies((prev) => ({
            ...prev,
            [option]: prev[option] === "denied" ? "granted" : "denied",
        }));

    const closeHandler = () => {
        if (open) {
            close();
        }
        setDecisionMade(true);
    };

    return decisionMade ? null : (
        <Card
            sx={{
                position: "fixed",
                borderRadius: 2,
                padding: 3,
                bottom: positionY === "bottom" ? 58 : "auto",
                top: positionY === "top" ? 18 : "auto",
                left: positionX === "left" ? 18 : "auto",
                right: positionX === "right" ? 18 : "auto",
                zIndex: 110,
            }}
            elevation={6}
            cy-data="consent-cookie-card"
        >
            <Grid
                container
                spacing={2}
                maxWidth={managePreference ? 500 : 350}
                sx={{ transition: "width 500ms linear" }}
            >
                {managePreference ? (
                    <EnlargedForm
                        cookieTypes={DOMAIN_CONFIG_OBJ.cookies_consent}
                        handleManagePreferences={togglePreference}
                        acceptAll={handleAcceptAll}
                        rejectAll={handleRejectAll}
                        preferedCookies={preferedCookies}
                        choosePreference={handleChoosePreference}
                        savePreference={handleSavePreference}
                        loading={loading}
                    />
                ) : (
                    <MinimizedForm
                        handleManagePreferences={togglePreference}
                        acceptAll={handleAcceptAll}
                        rejectAll={handleRejectAll}
                        loading={loading}
                        closeHandler={closeHandler}
                    />
                )}
            </Grid>
        </Card>
    );
};

export default CookieConsent;
