import ReactDOM from "react-dom";
import Auth from "@aws-amplify/auth";
import { DOMAIN_CONFIG_OBJ, QBOOK_URL } from "./utils/axiosInstance";
import "./styles/css/index.css";
import { Provider } from "react-redux";
import store from "./reduxStore/store";
import App from "./app/App";
import { Helmet } from "react-helmet";
import { QBOOK_DOMAIN_VALUES } from "./utils/constants/domainNameValues";

// configuring aws auth dynamically
Auth.configure({
  region: "us-east-1",
  userPoolId: DOMAIN_CONFIG_OBJ.awsCognito.cognitoPoolId,
  userPoolWebClientId: DOMAIN_CONFIG_OBJ.awsCognito.cognitoClientId,
  authenticationFlowType: "USER_SRP_AUTH",
  oauth: {
    domain: DOMAIN_CONFIG_OBJ.awsCognito.cognitoDomain,
    redirectSignIn: QBOOK_URL + "/oauth",
    redirectSignOut: QBOOK_URL + "/oauth",
    responseType: "code",
    scope: ["email", "openid", "read:user", "user:email"],
  },
});

ReactDOM.render(
  <Provider store={store}>
    {/* Using Helmet to dynamically set head elements */}
    <Helmet>
      <meta charSet="utf-8" />
      <title>qbook | {DOMAIN_CONFIG_OBJ.domainName}</title>
      {/* Setting favicon based on QBOOK_DOMAIN */}
      <link
        rel="icon"
        type={
          DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam
            ? "image/jpg"
            : "image/png"
        }
        href={DOMAIN_CONFIG_OBJ.favicon}
        sizes="16x16"
      />
    </Helmet>
    <App />
  </Provider>,
  document.getElementById("root"),
);
