import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import {
  QUERY_COURSE,
  QUERY_FILE,
} from "../../../../utils/constants/urlPramsConstants";
import { LearnInfoType } from "../../../../reduxStore/types/LearnTypes";
import { useAppDispatch, useAppSelector } from "../../../../reduxStore/hooks";
import { registerInToCourseAction } from "../../../../reduxStore/middleware/learnMiddleware";
import React, { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import Swal from "sweetalert2";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";
import { useNavigate } from "react-router-dom";

type Props = {
  courseName: string;
  courseDescription: string;
  totalStudents: string | number;
  content: LearnInfoType[];
  courseId: string;
  courseAuthor: string;
  visibility?: string;
  courseOwnerId: string;
  courseProgress?: number;
};

const CourseCard = ({
  courseName,
  courseDescription,
  content,
  totalStudents,
  courseId,
  courseAuthor,
  courseOwnerId,
  courseProgress,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.auth);
  const [register, setRegister] = useState(false);
  const handleViewCourseClick = () => {
    // if course owner id and user id is same, redirect user to his own course
    // if course progress is present means he is already registered for the course, no need for reregistration
    if (userData) {
      if (
        courseOwnerId === userData?._id ||
        (courseProgress !== null && courseProgress !== undefined)
      ) {
        console.log("Course Owner");
        return (window.location.href = `/learn/?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${content[0]._id}`);
      }
      setRegister(true);
      dispatch(registerInToCourseAction({ courseId: courseId })).then(
        (data: any) => {
          if (data?.payload?.status === 200 || data?.payload?.status === 409) {
            // 200 : registration success, 409 : already registered
            // store the courseId in local storage, to prevent subsequent registration request for this course from /learn/ route
            // localStorage.setItem('qbookCurrentCourse',courseId)
            return navigate(
              `/learn/?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${content[0]._id}`
            );
          }
          Swal.fire({
            icon: "error",
            title: "Errored",
            text:
              data?.payload?.data?.message ||
              "Something went wrong, Please try again.",
          });
          setRegister(false);
          // if registration errored due to some other reasons don't forward to course page
          return;
        }
      );
    }
    // if user is not present only public courses will be loaded, so redirect to /learn/
    return navigate(
      `/learn/?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${content[0]._id}`
    );
  };
  return (
    <Grid
      item
      container
      sm={12}
      md={6}
      lg={4}
      xl={4}
      onClick={() => handleViewCourseClick()}
      cy-data="qbook-landing-course-card"
      padding=".5em"
    >
      <Grid
        container
        sx={{
          backgroundColor: "var(--landing-course-card-bg)",
          borderRadius: "15px",
          boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
          minHeight: "inherit",
          cursor: "pointer",
          position: "relative",
          ":hover": {
            outline: "2px solid var(--landing-course-card-hover-border)",
          },
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          // sm={12}
          // md={4}
          // lg={4}
          // sx={{ backgroundColor: "var(--accent-clr2)" }}
          sx={{ background: "var(--landing-course-bg)" }}
          padding="20px "
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          overflow="hidden"
          justifyContent="space-between"
          minHeight={100}
          maxHeight={100}
        >
          <Box maxWidth="100%">
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 700,
                fontFamily: "var(--global-font-family-secondary)",
                color: "white",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "initial",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              cy-data="qbook-landing-course-card-name"
              title={courseName}
            >
              {courseName}
            </Typography>
          </Box>
          {/* <Button
            sx={{
              textTransform: "none",
              border: "none",
              color: "lightgray",
              padding:0,
              ":hover": {
                color: "gray",
              }
            }}
          >
            {"View Chapters >"}
          </Button> */}
        </Grid>
        <Grid
          item
          container
          xs={12}
          // sm={12}
          // md={8}
          // lg={8}
          padding="20px"
          justifyContent="space-between"
          alignContent="space-between"
          height="100%"
          maxHeight="fit-content"
        >
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item xs={3}>
              <Typography
                variant="body1"
                sx={{ color: "var(--landing-course-card-text3)" }}
              >
                Description
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sx={() =>
                courseProgress !== null && courseProgress !== undefined
                  ? {}
                  : { display: "flex", justifyContent: "flex-end" }
              }
            >
              {userData?._id && (
                <Box minHeight="24px">
                  {courseProgress !== null && courseProgress !== undefined ? (
                    <LinearProgressWithLabel
                      value={parseFloat(courseProgress.toString())}
                    />
                  ) : (
                    <Chip
                      icon={<FaceIcon />}
                      label="Enroll now"
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "var(--landing-course-card-text2)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "initial",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                  cy-data="qbook-landing-course-card-description"
                >
                  {courseDescription}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              // marginTop={2}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "1em",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    color: "var(--landing-course-card-text3)",
                  }}
                >
                  Author :
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    color: "var(--landing-course-card-text3)",
                  }}
                >
                  {courseAuthor || "N/A"}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 600,
                  color: "var(--landing-course-card-text2)",
                }}
                cy-data="landing-course-card-content-length"
              >{`Chapters : ${content?.length || 0}`}</Typography>
            </Box>

            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                color: "var(--landing-course-card-text3)",
                // position: "absolute",
                bottom: 20,
              }}
              cy-data="landing-course-card-students-count"
            >
              {`Enrolled Students ${
                totalStudents !== null || totalStudents !== undefined
                  ? totalStudents
                  : "N/A"
              }`}
            </Typography>
          </Grid>
        </Grid>

        <Dialog
          open={register}
          disablePortal
          sx={{
            position: "absolute",
            ":root": { position: "absolute" },
            "& .MuiBackdrop-root": {
              position: "absolute",
            },
            "& .MuiDialog-root": {
              position: "absolute",
            },
          }}
        >
          <DialogContent>
            <Stack
              flexDirection="row"
              gap={1}
              cy-data="qbook-landing-course-card-loader"
            >
              <ThreeCircles
                height="30"
                width="30"
                color={"var(--accent-clr2)"}
                ariaLabel="grid-loading"
                wrapperClass=""
                visible
              />
              <Typography fontSize={16} color="text.primary">
                {"Processing."}
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default React.memo(CourseCard);
