import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  clearIsLoading,
  handleAddBokeh,
  handleStartLoading,
  setIsLoading,
} from "../features/bokehSlice";
import { retrieveBokehAsync } from "../services/bokehService";

const timeout = (ms: any) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const loadBokehPackageAction = createAsyncThunk(
  "bokeh/loadBokeh",
  async (_, thunkAPI) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const { bokehPackage, isLoading } = appState.bokeh;
      if (bokehPackage) {
        return thunkAPI.fulfillWithValue(bokehPackage);
      } else if (isLoading) {
        while (!bokehPackage) {
          await timeout(200);
        }
        return thunkAPI.fulfillWithValue(bokehPackage);
      } else {
        const response = await new Promise(async (resolve, reject) => {
          thunkAPI.dispatch(setIsLoading());
          thunkAPI.dispatch(handleStartLoading());
          let firstScript = document.createElement("script");
          firstScript.type = "text/javascript";
          firstScript.async = true;
          firstScript.src =
            "https://cdn.pydata.org/bokeh/release/bokeh-2.2.3.min.js";
          firstScript.addEventListener(
            "load",
            (e) => {
              let secondScript = document.createElement("script");
              secondScript.type = "text/javascript";
              secondScript.async = true;
              secondScript.src =
                "https://cdn.pydata.org/bokeh/release/bokeh-widgets-2.2.3.min.js";
              secondScript.addEventListener(
                "load",
                (e) => {
                  window.Bokeh.set_log_level("info");
                  thunkAPI.dispatch(clearIsLoading());
                  resolve(window.Bokeh);
                },
                false,
              );

              let head = document.getElementsByTagName("head")[0];
              head.appendChild(secondScript);
            },
            false,
          );
          let head = document.getElementsByTagName("head")[0];
          head.appendChild(firstScript);
        });
        return thunkAPI.fulfillWithValue(response);
      }
    } catch (err) {
      thunkAPI.rejectWithValue({ message: "Failed to load bokeh" });
    }
  },
);
export const retrieveBokehAction = createAsyncThunk(
  "bokeh/retrieveBokeh",
  async ({ uniqueValidator }: { uniqueValidator: any }, thunkAPI) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const { bokehs } = appState.bokeh;
      const bokeh = bokehs.filter(
        (file) => file.uniqueValidator === uniqueValidator,
      );
      if (bokeh.length > 0) {
        return thunkAPI.fulfillWithValue(bokeh[0]);
      } else {
        const response = await retrieveBokehAsync(uniqueValidator);
        if (response.data && response.data.length) {
          thunkAPI.dispatch(
            handleAddBokeh({
              bokeh: response.data[0],
            }),
          );
          return thunkAPI.fulfillWithValue(response.data[0]);
        }
        return thunkAPI.rejectWithValue({
          index: null,
          err: "failed to load bokeh",
        });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ index: null, err: "failed to load" });
    }
  },
);
