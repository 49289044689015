import { Grid, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../../../reduxStore/hooks";
type Props = {};

const BlogProfileCard = (props: Props) => {
  const { userData } = useAppSelector((state) => state.auth);
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        boxShadow: 3,
        borderRadius: "8px",
        padding: "1em",
        justifyContent: "space-between",
      }}
    >
      <Grid item xs={8}>
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: "20px",
            color: "#3b3b3b",
          }}
        >
          {userData?.firstName || userData?.userName || "Admin User"}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: "#7b7b7b",
          }}
        >
          Author/Writer
        </Typography>
      </Grid>
      {/* <Grid item xs={3} sx={{ placeSelf: "flex-end" }}>
        <img src={author} alt="Author icon" width="100%" height="auto" />
      </Grid> */}
      <Grid
        item
        xs={12}
        sx={{ display: "flex", gap: "1em", placeSelf: "flex-end" }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            color: "#7b7b7b",
          }}
        >
          Total Blogs : {userData?.blogsSubmitted?.length}
        </Typography>
        {/* <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            color: "#7b7b7b",
          }}
        >
          Total Subscribers : 20
        </Typography> */}
      </Grid>
    </Grid>
  );
};

export default BlogProfileCard;
