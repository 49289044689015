import { useEffect } from "react";
import Tracker from "@openreplay/tracker";
import { useAppSelector } from "../../../reduxStore/hooks";
import { email } from "../../../utils/axiosInstance";

const path = window.location.href;
const devMode = path.includes("staging") || path.includes("localhost");

const tracker = new Tracker({
    projectKey: process.env.REACT_APP_OPENREPLAY_KEY || "_blank", // "_blank" is provided so that if openreplay_key is not present, it doesnt emits a type Error.
    ingestPoint: "https://openreplay.qbraid.com/ingest",
    // __DISABLE_SECURE_MODE: true, // only to be enabled for local testing
});

const OpenReplay = ({ children }: any) => {
    const { userData } = useAppSelector((state) => state.auth);
    useEffect(() => {
        if (tracker.isActive() || !email || !userData?.metadata || devMode)
            return;

        if (
            userData?.metadata?.cookieConsentObject?.analytics_storage ===
            "granted"
        ) {
            tracker.setUserID(email);
            tracker.start().catch((err) => {
                console.log("Openreplay session cannot be started!", err);
            });
        }
    }, [userData]);
    return children;
};

export default OpenReplay;
