import { createSlice } from "@reduxjs/toolkit";
export interface ThemeState {
  isDark: boolean;
}
const initialState: ThemeState = {
  isDark: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    toggleTheme: (state, { payload }) => {
      state.isDark = payload;
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
