import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { loadCourseTutorProfilesAction } from "../middleware/tutorMiddlewares";
import { TutorProfileType } from "../types/TutorTypes";
export interface TutorState {
  tutorProfilesArray: TutorProfileType[];
  loadingTutorProfiles: boolean;
  failedToLoadTutorProfiles: boolean;
}
const initialState: TutorState = {
  tutorProfilesArray: [],
  loadingTutorProfiles: true,
  failedToLoadTutorProfiles: false,
};

const tutorSlice = createSlice({
  name: "tutor",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // loading public courses
    builder.addCase(loadCourseTutorProfilesAction.pending, (state) => {
      state.loadingTutorProfiles = true;
      state.failedToLoadTutorProfiles = false;
    });
    builder.addCase(
      loadCourseTutorProfilesAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const combinedArray = [...state.tutorProfilesArray, ...action.payload];
        const filteredArray = combinedArray.filter((obj, index) => {
          return (
            index ===
            combinedArray.findIndex((o) => obj.courseName === o.courseName)
          );
        });
        state.tutorProfilesArray = filteredArray;
        state.loadingTutorProfiles = false;
        state.failedToLoadTutorProfiles = false;
      },
    );
    builder.addCase(loadCourseTutorProfilesAction.rejected, (state, action) => {
      state.loadingTutorProfiles = false;
      state.failedToLoadTutorProfiles = true;
    });
  },
});

export default tutorSlice.reducer;
