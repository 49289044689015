import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./UnauthorizedUI.css";
import { useAppSelector } from "../../../../../reduxStore/hooks";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";
type Props = {};

const UnauthorizedUI = (props: Props) => {
  const { isDark } = useAppSelector((state) => state.theme);
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "15px",
        flexWrap: "nowrap",
        padding: "1em",
      }}
      cy-data="admin-un-authorize"
    >
      <img
        src={
          isDark
            ? DOMAIN_CONFIG_OBJ.admin.unauthorized.unauthorizedImage
                .darkThemeImage.image
            : DOMAIN_CONFIG_OBJ.admin.unauthorized.unauthorizedImage
                .darkThemeImage.image
        }
        alt="unauthorized_image"
        className="unauthorized-ui-img"
        style={{
          filter: isDark ? "invert(50)" : "",
          borderRadius: isDark ? "10px" : "",
        }}
      />
      <Typography color="var(--text-primary-clr1)">
        {DOMAIN_CONFIG_OBJ.admin.unauthorized.unauthorizedText}
      </Typography>

      <Button
        variant="qbookStyledBtn"
        sx={{ textTransform: "none" }}
        onClick={() => (window.location.href = "/")}
      >
        Back to home
      </Button>
    </Grid>
  );
};

export default UnauthorizedUI;
