import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISnackbarState } from "../types/snackbarTypes";

const initialState: ISnackbarState = {
  open: false,
  severity: "info",
  autoHideDuration: 3000,
  message: "",
  anchorOrigin: { vertical: "top", horizontal: "left" },
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: initialState,
  reducers: {
    handleSnackbarOpen: (state, action: PayloadAction<ISnackbarState>) => {
      state.open = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
      if (action.payload.anchorOrigin)
        state.anchorOrigin = action.payload.anchorOrigin;
      if (action.payload.autoHideDuration)
        state.autoHideDuration = action.payload.autoHideDuration;
    },
    handleSnackbarClose: (state) => {
      state.open = false;
    },
  },
});

export const { handleSnackbarOpen, handleSnackbarClose } =
  snackbarSlice.actions;
export default snackbarSlice.reducer;
