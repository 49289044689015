import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createWebsocket,
  executeCode,
  getKernelAction,
  interruptKernelAction,
  killWebsocket,
  prepareRequest,
  restartKernelAction,
  runStartupSequenceAction,
  shutdownKernelAction,
  startKernelAction,
} from "../middleware/kernelMiddleware";

export interface KernelStoreState {
  kernels: Record<string, any>;
  currentKernel?: any;
  kernelState: string;
  websocket: WebSocket | undefined;
  executionQueue: any[];
}
const initialState: KernelStoreState = {
  kernels: {},
  currentKernel: undefined,
  kernelState: "Not Started",
  websocket: undefined,
  executionQueue: [],
};

const kernelSlice = createSlice({
  name: "kernel",
  initialState: initialState,
  reducers: {
    // return kernal in flux returns the current kernel to caller
    // in redux getState() will do this function
    returnKernels: () => {},
    handlePopulateKernels: (state, { payload }) => {
      const { kernels } = payload;
      let kernelsDict: Record<string, any> = {};
      for (let kernel of kernels) {
        kernelsDict[kernel.id] = kernel;
      }
      state.kernels = kernelsDict;
    },
    handleAddKernel: (state, { payload }) => {
      const { kernel } = payload;
      state.kernels[kernel.id] = kernel;
    },
    handleDeleteKernel: (state, { payload }) => {
      const { kernel_id } = payload;
      delete state.kernels[kernel_id];
    },
    handleStoreCurrentKernel: (state, { payload }) => {
      const { kernel } = payload;
      state.currentKernel = kernel;
    },
    handleUpdateKernelState: (state, { payload }) => {
      state.kernelState = payload;
    },
    handleStoreWebsocket: (state, { payload }) => {
      const { websocket } = payload;
      state.websocket = websocket;
    },
    handleEnqueue: (state, { payload }) => {
      const { executionRequest } = payload;
      state.executionQueue = [...state.executionQueue, executionRequest];
    },
    handleDequeue: (state, { payload }) => {
      const { messageId } = payload;
      if (
        state.executionQueue.length > 0 &&
        state.executionQueue[0]?.header?.msg_id === messageId
      ) {
        let queue = state.executionQueue;
        // queue[0].completeCallback(); // calls the function stashed in the dictionary
        queue.shift(); // removes the first element from the queue
        state.executionQueue = queue;
      } else {
        console.log(
          `Dequeue operation failed because message ids did not match`,
        );
      }
    },
    handleClearQueue: (state, { payload }) => {
      let queue = state.executionQueue;
      queue.forEach(
        (item) => item?.completeCallback && item?.completeCallback(),
      ); // does a completion callback for all successive cells
      state.executionQueue = []; // clears the queue
    },
  },
  extraReducers: (builder) => {
    // start up action kernel handlers
    builder.addCase(runStartupSequenceAction.pending, (state) => {});
    builder.addCase(
      runStartupSequenceAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(runStartupSequenceAction.rejected, (state, action) => {});

    // start kernel handlers
    builder.addCase(startKernelAction.pending, (state) => {});
    builder.addCase(
      startKernelAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(startKernelAction.rejected, (state, action) => {});

    // get kernel handlers
    builder.addCase(getKernelAction.pending, (state) => {});
    builder.addCase(
      getKernelAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(getKernelAction.rejected, (state, action) => {});

    // shutdown kernel handlers
    builder.addCase(shutdownKernelAction.pending, (state) => {});
    builder.addCase(
      shutdownKernelAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(shutdownKernelAction.rejected, (state, action) => {});

    // restart kernel handlers
    builder.addCase(restartKernelAction.pending, (state) => {});
    builder.addCase(
      restartKernelAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(restartKernelAction.rejected, (state, action) => {});

    // interupt kernel handlers
    builder.addCase(interruptKernelAction.pending, (state) => {});
    builder.addCase(
      interruptKernelAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(interruptKernelAction.rejected, (state, action) => {});
    // create websoket
    builder.addCase(createWebsocket.pending, (state) => {});
    builder.addCase(
      createWebsocket.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.kernelState = "Ready";
        state.websocket = action.payload;
      },
    );
    builder.addCase(createWebsocket.rejected, (state, action) => {
      state.kernelState = "Failed";
      state.websocket = undefined;
    });
    // execute code
    builder.addCase(executeCode.pending, (state) => {});
    builder.addCase(
      executeCode.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(executeCode.rejected, (state, action) => {});
    // prepare request
    builder.addCase(prepareRequest.pending, (state) => {});
    builder.addCase(
      prepareRequest.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(prepareRequest.rejected, (state, action) => {});
    builder.addCase(killWebsocket.pending, () => {});
    builder.addCase(killWebsocket.fulfilled, () => {});
    builder.addCase(killWebsocket.rejected, () => {});
  },
});

export const {
  handlePopulateKernels,
  handleAddKernel,
  handleClearQueue,
  handleDeleteKernel,
  handleDequeue,
  handleEnqueue,
  handleStoreCurrentKernel,
  handleStoreWebsocket,
  handleUpdateKernelState,
} = kernelSlice.actions;
export default kernelSlice.reducer;
