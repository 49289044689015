/**
 * @fileOverview This file handles all cookie related functionalities
 * @module cookieHelper
 */

import Cookies from "universal-cookie";
import { DOMAIN_CONFIG_OBJ } from "./axiosInstance";

const isLocalHost =
  (window.location.href.match("localhost:300") || []).length === 1;
const cookieEnder = isLocalHost
  ? ";path=/;SameSite=Lax"
  : `;domain=${DOMAIN_CONFIG_OBJ.domain};path=/;SameSite=Lax`;
const domain = !isLocalHost ? DOMAIN_CONFIG_OBJ.domain : "localhost";

// return email and refreshToken
export const getEmailRefreshCookies = () => {
  let cookieList: any = document.cookie.split(";");
  cookieList = cookieList.map((cookie: any) => {
    return { key: cookie.split("=")[0], value: cookie.split("=")[1] };
  });

  let email = "";
  let refreshToken = "";
  for (let cookie of cookieList) {
    if (cookie.key === "EMAIL" || cookie.key === " EMAIL") {
      email = cookie.value;
    } else if (cookie.key === "REFRESH" || cookie.key === " REFRESH") {
      refreshToken = cookie.value;
    }
  }

  return { email, refreshToken };
};
// set cookies

export const setCookies = (
  email: string,
  refreshToken: string,
  oneWeek: any,
) => {
  document.cookie = "EMAIL=" + email + ";expires=" + oneWeek + cookieEnder;
  document.cookie =
    "REFRESH=" + refreshToken + ";expires=" + oneWeek + cookieEnder;
};
// clear cookies
export const clearCookies = () => {
  let { email } = getEmailRefreshCookies();
  document.cookie =
    "_xsrf=;expires=Thu, 01 Jan 1970 00:00:00 GMT" +
    ";path=/;SameSite=none;Secure=false";
  document.cookie =
    "jupyterhub-session-id=;expires=Thu, 01 Jan 1970 00:00:00 GMT" +
    cookieEnder;
  document.cookie =
    "jupyterhub-hub-login=;expires=Thu, 01 Jan 1970 00:00:00 GMT" + cookieEnder;
  document.cookie =
    "jupyterhub-user-" +
    encodeURIComponent(email) +
    "=;expires=Thu, 01 Jan 1970 00:00:00 GMT" +
    cookieEnder;
  document.cookie =
    "_xsrf=;expires=Thu, 01 Jan 1970 00:00:00 GMT" + cookieEnder;
  // clear user cookies
  document.cookie =
    "EMAIL=;expires=Thu, 01 Jan 1970 00:00:00 GMT" + cookieEnder;
  document.cookie =
    "REFRESH=;expires=Thu, 01 Jan 1970 00:00:00 GMT" + cookieEnder;
  const cookies = new Cookies();
  cookies.remove("GTAGID", {
    sameSite: "lax",
    path: "/",
    domain: domain,
  });
};

//   clear local storage
export const clearLocalStorage = () => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  for (let [key, value] of Object.entries(window.localStorage)) {
    if (
      key.includes("accessToken") ||
      key.includes("idToken") ||
      key.includes("refreshToken") ||
      key.includes("userData")
    ) {
      window.localStorage.removeItem(key);
    }
  }
};
