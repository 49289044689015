import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react'

function LinearProgressWithLabel(props:any) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-end',flexDirection:'column' }}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${Math.round(props.value)}% Progress`}
          </Typography>
        </Box>
      </Box>
    );
  }

export default LinearProgressWithLabel
