import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function CustomSearchField({
  width,
  value,
  placeHolder,
  handler,
  enableAutoFocus,
  showButton,
  buttonHandler,
}: {
  width?: string;
  value: string;
  placeHolder?: string;
  handler: (text: string) => void;
  buttonHandler?: () => void;
  enableAutoFocus?: boolean;
  showButton: boolean;
}) {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: 700,
        width: { xs: 300, sm: 300, md: width || 400, lg: width || 400 },
        borderRadius: "var(--landing-page-btn-border-radius)",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeHolder || "Search Courses"}
        inputProps={{ "aria-label": "search courses" }}
        value={value}
        onChange={(e) => {
          e.preventDefault();
          handler(e.target.value);
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13 || event.key === "Enter") {
            event.preventDefault();
          }
        }}
        autoFocus={enableAutoFocus}
      />
      {showButton && (
        <Button
          variant="qbookStyledBtn"
          sx={{
            textTransform: "none",
            borderRadius: "var(--landing-page-btn-border-radius)",
          }}
          endIcon={<SearchIcon />}
          onClick={() => buttonHandler && buttonHandler()}
        >
          Search
        </Button>
      )}
    </Paper>
  );
}
